<div class="wen-channel-message-detail-desktop-content">
  <wen-desktop-sub-header height="static">
      <div class="wen-channel-message-detail-desktop-header">
        <wen-active-item-image-view [imageUrl$]="channelImageUrl$"
                                    [overlayData$]="channelOverlayData$"
                                    [overlaySize]="'medium'"
                                    [placeholder$]="channelPlaceholder$"
                                    class="wen-channel-message-detail-desktop-header-image"></wen-active-item-image-view>
        <h2>{{ 'CHANNEL_COMMENTS_TITLE' | translate }}</h2>
      </div>
  </wen-desktop-sub-header>

  <wen-channel-message-detail class="wen-channel-message-detail-nested"></wen-channel-message-detail>
</div>
