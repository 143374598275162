import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EmbedDTOType, EmbedDTOTypes, isForwardEmbed, MessageModificationState, RoomMessageDTO } from '@portal/wen-backend-api';
import { QuoteData } from '@portal/wen-components';
import { filter, first, map, Observable, switchMap } from 'rxjs';
import { chatViewIdentifier } from '../../../views/chat/tokens';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { updateChatDraftMessage } from '../../store/chat/chat.actions';
import { ChatMessageEntity } from '../../store/chat/chat.state';
import { selectChatMessageById } from '../../store/chat/selectors/chat-message-selectors';
import { RootState } from '../../store/root/public-api';
import { selectRouteParam } from '../../store/root/root.selectors';
import { QuoteHandler } from './quote-handler';
import { isNullOrUndefined } from '../../common/operators/null-check-util';
import { TranslateService } from '@ngx-translate/core';

export type QuoteInfo = Pick<RoomMessageDTO, 'content' | 'embeds'>;

@Injectable()
export class ChatQuoteHandler extends QuoteHandler {

  private whitelistedEmbeds: EmbedDTOType[] = [EmbedDTOType.MEDIA, EmbedDTOType.LINK];

  private store = inject(Store<RootState>);
  private translationService = inject(TranslateService);

  retrieveQuote(quotedItemId: string): Observable<QuoteData> {
    return this.store.pipe(
      select(selectRouteParam(chatViewIdentifier)),
      switchMap((roomId) => this.store.pipe(selectorWithParam(selectChatMessageById, roomId, quotedItemId))),
      filter((message) => !isNullOrUndefined(message)),
      map((chatMessage: ChatMessageEntity) => {
        const { messageContent, state } = chatMessage;
        const quoteInfo = this.retrieveQuoteInfo(messageContent?.embeds);
        const quoteData: QuoteData = {
          id: quotedItemId,
          author: chatMessage?.insertUser?.name || '',
          text: !this.isMessageDeleted(state)
                    ? messageContent?.content || quoteInfo?.content || ''
                    : this.translationService.instant('CHAT_MESSAGE_DELETED'),
          isDeleted: this.isMessageDeleted(state),
          ...quoteInfo?.embeds?.length && { embed: this.filterForFirstQuotableEmbed(quoteInfo?.embeds) }
        };
        return quoteData;
      })
    );
  }

  initiateQuote(quotableId: string): void {
    this.store.pipe(
      select(selectRouteParam(chatViewIdentifier)),
      first()
    ).subscribe((contextId) => {
      this.store.dispatch(updateChatDraftMessage({
        message: {
          contextId,
          quote: {
            quotedItemId: quotableId,
            type: EmbedDTOType.QUOTE
          }
        }
      }));
    });
  }

  private retrieveQuoteInfo(embeds: EmbedDTOTypes[]): QuoteInfo {
    if (isNullOrUndefined(embeds) && !this.whitelistedEmbeds?.length || !embeds?.length) {
      return null;
    }
    const mappedEmbeds = embeds.map(embed => {
      if (isForwardEmbed(embed)) {
        return {
          content: embed.forwardData.data.content,
          embeds: embed.forwardData.data?.embeds ?? []
        };
      }
      return {
        content: null,
        embeds: [embed]
      };
    });
    return mappedEmbeds[0];
  }

  private filterForFirstQuotableEmbed(embeds: EmbedDTOTypes[]): EmbedDTOTypes {
    const quotableEmbeds = embeds.filter(embed => this.whitelistedEmbeds.includes(embed.type));
    return quotableEmbeds[0];
  }

  private isMessageDeleted(state: MessageModificationState): boolean {
    return state === MessageModificationState.DELETED;
  }

}
