import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FeatureEnablementService } from '../../../../../../../../core/services/configuration/feature-enablement';
import { LazyLoadParams } from '@portal/wen-components';
import { ThumbnailProvider } from '../../directives/thumbnail-evaluator.directive';
import { MediaViewParams, MediaEventOptions } from '../../models/models';
import { TranslateService } from '@ngx-translate/core';
import { getMediaAltText } from '../../media-utils';

@Component({
  selector: 'wen-embed-video',
  templateUrl: './embed-video.component.html',
  styleUrls: ['./embed-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedVideoComponent implements AfterViewInit, OnDestroy {

  private params: MediaViewParams;
  private retry = 0;

  isWeLocalPlayerNeeded: boolean;
  sourceUrl: string;
  mediaAltText = '';
  thumbnail$: Observable<LazyLoadParams> = this.thumbnailProvider.thumbnail$.pipe(
    map(({ url, scaledDimensions: { width, height } }) => {
      return {
        src: url,
        originalWidth: width,
        originalHeight: height
      };
    })
  );

  @ViewChild('image') imageElement: ElementRef<HTMLImageElement>;

  @HostBinding('class.wen-video-embed') className = true;

  @Input() set config(params: MediaViewParams) {
    this.params = params;
    this.isWeLocalPlayerNeeded = params.isWeLocalPlayerNeeded;
    this.sourceUrl = params.sourceUrl;
  }

  @Output() mediaClick = new EventEmitter<MediaEventOptions>();

  constructor(
    private featureEnablementService: FeatureEnablementService,
    private thumbnailProvider: ThumbnailProvider,
    private translateService: TranslateService
  ) { }

  ngAfterViewInit() {
    this.mediaAltText = getMediaAltText(this.translateService, this.params.mediaType, 'MEDIA_ATTACHMENT_DESCRIPTION');
  }

  onMediaClick() {
    this.mediaClick.next({ mediaId: this.params.id });
  }

  get canUseNewVideoPlayer() {
    return this.featureEnablementService.featureFlagMethods.isEnableNewVideoPlayer();
  }

  ngOnDestroy(): void {
    this.params = null;
  }
}
