<wen-adaptive-background [desktopWidth]="375" wenIosSwipeSuppressor>
  <wen-dialog-layout wen-adaptive-background-content [headerGravity]="'top'">
    <div class="wen-error-page-content-wrapper" dialogLayoutContent>
      <div portal-body class="wen-error-page-body">
        @if ((showClientError | async)) {
          <img class="wen-error-page-image" src="/assets/wen-widget/error-page/error-no-internet-robot.svg" alt="" >
          <div portal-title class="wen-error-page-title">
            {{ "ERROR_PAGE_OFFLINE_TITLE" | translate }}
          </div>
          <div class="wen-error-page-content">
            {{ "ERROR_PAGE_OFFLINE_CONTENT" | translate }}
          </div>
        } @else {
          <ng-lottie [options]="lottieOptions" (animationCreated)="onAnimationCreated($event)" role="presentation"></ng-lottie>
          <div portal-title class="wen-error-page-title">
            {{ "ERROR_PAGE_TITLE" | translate }}
          </div>
          <div class="wen-error-page-content">
            {{ "ERROR_PAGE_CONTENT" | translate }}
          </div>
          <!--
          TODO: Add error page link from design once we have the URL
          Translations for it are already added: "ERROR_PAGE_INFO_LINK_LABEL" and "ERROR_PAGE_INFO"
          -->
        }
      </div>
    </div>
  </wen-dialog-layout>
</wen-adaptive-background>



