import { inject, Injectable } from '@angular/core';
import { TracerRuntimeParams } from '../config-types';
import { SentryLoader } from './sentry-loader';

@Injectable()
export class TracerInitializer {

  private sentryLoader = inject(SentryLoader);

  enableSentry(runtimeParams?: TracerRuntimeParams) {
    return this.sentryLoader.attach(runtimeParams);
  }

  disableSentry() {
    return this.sentryLoader.detach();
  }

}
