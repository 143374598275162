import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { EmbedDTOType, EmbeddedMediaDTO } from '@portal/wen-backend-api';
import { Observable, filter, first, map, of, shareReplay, startWith, switchMap, timer, withLatestFrom } from 'rxjs';
import { DateUtil } from '../../../../core/common/date/date-util';
import { switchMapFirst } from '../../../../core/common/operators/switch-map-first';
import { DistributionChatAttachmentLoader } from '../../../../core/services/smartdesign/chat-distribution/distribution-chat-attachment-loader';
import { DistributionChatMessageProcessor } from '../../../../core/services/smartdesign/chat-distribution/distribution-chat-message-processor';
import { DistributionDataImporter } from '../../../../core/services/smartdesign/chat-distribution/distribution-data-importer';
import { UserData } from '../../../../core/store/auth/models/UserData';
import { RootState } from '../../../../core/store/root/public-api';
import { WeFeedDataset, WeFeedItemType, WeFeedMessageItem } from '../../../../shared/components/feed/components/we-feed/we-feed-model';
import { FeedDatasource } from '../../../../shared/components/feed/providers/feed-datasource';

@Injectable()
export class ChatPreviewMessageFeedDatasource extends FeedDatasource {

  constructor(
    protected readonly store: Store<RootState>,
    private readonly distributionDataImporter: DistributionDataImporter,
    private readonly distributionChatMessageProcessor: DistributionChatMessageProcessor,
    private readonly distributionChatAttachmentLoader: DistributionChatAttachmentLoader,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super(store);
  }

  bindToSource(): Observable<WeFeedDataset> {
    const wenetworkId = this.activatedRoute.snapshot.queryParams.id;
    const wenetworkId$ = of(wenetworkId).pipe(
      switchMapFirst(id => {
        if (id) {
          return of(id);
        }
        return timer(0).pipe(switchMap(() => {
          return this.activatedRoute.queryParams.pipe(
            map((params: Params) => params.id),
            filter(wenId => Boolean(wenId)),
            first()
          );
        }));
      }),
    );
    const recipient$ = wenetworkId$.pipe(
      switchMap(id => this.distributionDataImporter.loadDistributionChatRecipient(id)),
      shareReplay(1)
    );
    const messageData$ = this.distributionDataImporter.loadDistributionChatMessage().pipe(
      shareReplay(1)
    );

    return messageData$.pipe(
      switchMap(messageData => {
        if (messageData.attachments?.length) {
          return this.distributionChatAttachmentLoader.loadAttachmentForPreview(messageData.attachments[0]).pipe(
            startWith({ type: EmbedDTOType.MEDIA } as EmbeddedMediaDTO),
            map(embed => ({ messageData, embed }))
          );
        }
        return of({ messageData, embed: null as EmbeddedMediaDTO });
      }),
      switchMap(({ messageData, embed }) => {
        return recipient$.pipe(
          map((recipient) => {
            return this.distributionChatMessageProcessor.processMessage(recipient.originalDO, messageData.message);
          }),
          withLatestFrom(this.currentUser$),
          map(([content, userData]) => {
            let feedItem = this.convertToFeedItem(content, userData);
            feedItem = {
              ...feedItem,
              value: {
                ...feedItem.value,
                embeds: Boolean(embed) ? [embed] : []
              }
            };
            const dataSet: WeFeedDataset = {
              items: [feedItem]
            };
            return dataSet;
          }),
        );
      }),
      shareReplay(1),
    );
  }

  loadNextPage(): Observable<{ hasResult: boolean }> {
    return of({ hasResult: false });
  }

  loadFirstPage(): Observable<{ hasResult: boolean }> {
    return of({ hasResult: false });
  }

  private convertToFeedItem(content: string, currentUser: UserData) {
    const feedItem: WeFeedMessageItem = {
      key: 'preview-message',
      type: WeFeedItemType.MESSAGEBOX,
      value: {
        messageId: 'preview-message',
        authorId: currentUser.userId,
        sender: '',
        currentUserUpdater: true,
        content,
        scheduled: true,
        embeds: [],
        timestamp: DateUtil.currentDateString(),
        shareVisible: false,
        commentsVisible: false,
        commentsEnabled: false,
        highlighted: false,
        wide: false,
        disableEmojiReaction: true,
        hideEmojiReactionPlaceholder: true,
        status: null,
        modificationState: null,
        textToSpeechEnabled: false,
        disableFooterFunctions: true,
      },
    };
    return feedItem;
  }

}
