import { Component, OnInit } from '@angular/core';
import { ContactResponseActionsHandler } from '../../../../../core/services/contact/contact-response-actions-handler';
import { ListMassDataViewerContainerConnector, provideSimpleMassDataContainerConnector } from '../../../../../core/services/mass-data/list-mass-data-viewer-container-connector';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { ContactListEntity, PendingContactEntity } from '../../../../../core/store/contacts/contacts.state';
import { PendingContactsMassDataContainer } from '../../../../../core/store/contacts/pending-contacts-mass-data-config/pending-contacts-mass-data-container';
import { WenRouteId } from '../../../../../frame/routing/types';
import { ContactResponseResponseActionData } from '../../../../../shared/components/contact-response-action/contact-response-action.types';
import { userProfileIdentifier } from '../../../../user-profile/tokens';
import { PendingContactListService } from '../../providers/pending-contact-list-service';
import { HeaderDataHelper } from '../../../../../core/services/util/header-data-helper';

@Component({
  selector: 'wen-pending-contacts-list',
  templateUrl: './pending-contacts-list.component.html',
  styleUrls: ['./pending-contacts-list.component.scss'],
  providers: [
    PendingContactListService,
    ContactResponseActionsHandler,
    provideSimpleMassDataContainerConnector(PendingContactsMassDataContainer),
  ]
})
export class PendingContactsListComponent implements OnInit {

  constructor(
    public appNavigator: AppNavigator,
    public actions: ContactResponseActionsHandler,
    public listDatasource: ListMassDataViewerContainerConnector<PendingContactsMassDataContainer>,
    public listService: PendingContactListService,
    private headerDataHelper: HeaderDataHelper
  ) { }

  ngOnInit() {
    this.headerDataHelper.initializeContextTitleWithUserName(true);
  }

  handleListItemClick(item: PendingContactEntity) {
    this.appNavigator.navigateToRoute(WenRouteId.OTHER_USERPROFILE_READ, {
      [userProfileIdentifier]: item.user.id
    });
  }

  handleAcceptIncomingAfterClick(event: Event, item: PendingContactEntity) {
    event.stopPropagation();
    this.actions.handleAcceptReceivedContactRequest(item.user.id);
  }

  handleDenyIncomingAfterClick(event: Event, item: PendingContactEntity) {
    event.stopPropagation();
    this.actions.handleDenyReceivedContactRequest(item.user.id);
  }

  convertToResponseActionData(item: ContactListEntity): ContactResponseResponseActionData {
    const data: ContactResponseResponseActionData = {
      userId: item.user.id, requestStatus: item.requestStatus
    };
    return data;
  }

}

