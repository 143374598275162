import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { EmbeddedQuoteDTO } from '@portal/wen-backend-api';
import { QuoteData } from '@portal/wen-components';
import { combineLatest, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { QuoteHandler } from '../../../../../core/services/util/quote-handler';
import { MessageSendFieldDataSource } from '../../providers/message-send-field-data-source';

interface QuoteItem {
  data: QuoteData | false;
  isEditMode: boolean;
}

@Component({
  selector: 'wen-quote-embed-preview',
  templateUrl: './quote-embed-preview.component.html',
  styleUrls: ['./quote-embed-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteEmbedPreviewComponent {

  private dataSource = inject(MessageSendFieldDataSource);
  private quoteHandler = inject(QuoteHandler);

  private quoteData$: Observable<QuoteData | false> = this.dataSource.draftMessage$.pipe(
    switchMap(draftMessage => {
      if (!draftMessage?.quote) {
        return of(false);
      }
      return this.quoteHandler.retrieveQuote((draftMessage.quote as EmbeddedQuoteDTO).quotedItemId);
    }),
    shareReplay(1)
  );

  private isEditMode$: Observable<boolean> = this.dataSource.draftMessage$.pipe(
    map(message => Boolean(message?.id)),
    distinctUntilChanged(),
  );

  quoteItem$: Observable<QuoteItem> = combineLatest([this.quoteData$, this.isEditMode$]).pipe(
    map(([quoteData, isEditMode]) => {
      if (!quoteData) {
        return null;
      }

      return {
        data: quoteData,
        isEditMode
      };
    })
  );

  handleDismiss() {
    this.dataSource.updateDraftMessage({ quote: null });
  }

}
