import { TranslationOrString } from '@portal/wen-translation';
import { FilterEntityType } from '../../filter/models/filter';
import { BaseHeaderCommand, HeaderCommand } from './HeaderCommand';

export enum SearchFilterId {
  LIST_SEARCH = 'LIST_SEARCH',
  ADMIN_SEARCH = 'ADMIN_SEARCH',
  USER_SEARCH = 'USER_SEARCH',
  CONTACT_SEARCH = 'CONTACT_SEARCH',
}

export interface SearchConfig {
  searchBarPlaceholder?: string;
  hideSearchBar?: boolean;
  disabled?: boolean;
  disableAnimations?: boolean;
  filterConfig?: {
    filterId: SearchFilterId;
    filterEntityType: FilterEntityType;
  };
  navigateToFn?: () => void;
}

export interface CommandButtonStates {
  rightCommandDisabled?: boolean;
  leftCommandDisabled?: boolean;
}

export interface HeaderData {
  contextTitle?: TranslationOrString; // for accessibility reasons, will be used as hidden heading 1
  title?: TranslationOrString;
  subTitle?: TranslationOrString;
  titleIcon?: string;
  prefixTitleIcon?: string;
  floating?: boolean;
  transparent?: boolean;
  showLogo?: boolean;
  logoIcon?: string;
  leftCommand?: HeaderCommand;
  rightCommand?: HeaderCommand;
  centerCommand?: BaseHeaderCommand;
  searchConfig?: SearchConfig;
  commandButtonStates?: CommandButtonStates;
}
