@if (sectionItems$ | async; as sectionItems) {
  @if (sectionItems.length) {
    <wen-section [isNativeApp]="isNativeApp">
      @if (sectionName) {
        <ng-container section-header-title>{{ sectionName | translate }}</ng-container>
      }
      <div class="wen-section-show-all-title" section-header-action (click)="showAllElements()"
      [wen-test-id]="'wen-app-explore-section-show-secton-button'">{{ 'SHOW_ALL_LABEL' | translate }}</div>
      @for (item of sectionItems; track item.id) {
        <wen-card class="wen-card-narrow" [direction]="'column'" [elevate]="true"
          (click)="navigateToAppDetail(item)" section-content [wen-test-id]="'wen-app-explore-section-card'">
          <wen-circle-image-view [imageUrl]="item.icon" shape="square" [placeholderText]="item.title"
          [imageSize]="imageSize" card-image></wen-circle-image-view>
          <div card-title>{{item.title}}</div>
          <div card-description>{{ item.categories[0].name }}</div>
          <wen-quick-action-panel class="wen-icon-medium wen-app-downloaded-indicator"
            [iconName]="item.isSubscribed ? 'download_active' : 'download_inactive'" card-floating-panel
          (quickActionTriggered)="toggleAppSubscription(item.id, !item.isSubscribed)"></wen-quick-action-panel>
        </wen-card>
      }
      @if (sectionItems.length === sectionLimit) {
        <wen-card class="last-card wen-card-narrow" [elevate]="true"
          section-content (click)="showAllElements()">
          <div card-title>
            <span wen-primary-color>{{ 'SHOW_ALL_LABEL' | translate }}</span>
          </div>
        </wen-card>
      }
    </wen-section>
  }
}
