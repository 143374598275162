import { Injectable, inject } from '@angular/core';
import { ChatMessageComposerExtras } from '@portal/wen-backend-api';
import { SendMessageEncryptor } from '../encryption/send-message-encryptor';
import { ChatMessageBatchLoader, ChatMessageComposerTransactionFactory } from '@portal/wen-chat-client';

@Injectable()
export class EncryptedChatMessageSender {

  private transactionFactory = inject(ChatMessageComposerTransactionFactory);
  private sendMessageEncryptor = inject(SendMessageEncryptor);

  sendMessages(itemLoader: ChatMessageBatchLoader) {
    const extras: ChatMessageComposerExtras = {
      prepareMessage: (rawPayload) => {
        const { roomId, roomMessage: { message }, relation } = rawPayload;
        return this.sendMessageEncryptor.encryptChatMessage(roomId, { message }, relation);
      },
      enableEncryption: true
    };
    return this.transactionFactory.create(itemLoader, extras);
  }

}
