<div class="wen-location-selector-input-wrapper">
  <mat-form-field class="wen-location-selector-form-field wen-hide-underline wen-form-field-white" floatLabel="never">
    <input #searchInput matInput [placeholder]="inputPlaceholder | translate"
      [autocomplete]="'off'" [wen-test-id]="'location-selector-search-input'" (input)="onSearchInput()">
      @if (searchInputValue) {
        <wen-icon matSuffix class="wen-icon-medium" [iconName]="'close'" (click)="clearInput()"></wen-icon>
      }
    </mat-form-field>
  </div>
  <div class="wen-location-selector-results-scroller" cdkScrollable>
    @if (showResultList$ | async) {
      <div class="wen-location-selector-results-wrapper">
        @if (hasResultList$ | async) {
          @for (embedLocation of searchResult$ | async; track embedLocation) {
            <wen-location-list-item
              [model]="embedLocation"
              [searchTerm]="searchInputValue"
              [wen-test-id]="'location-search-result-item'"
              (click)="updateValue(embedLocation)"
            (prefillActionClicked)="prefillLocation($event)"></wen-location-list-item>
          }
        } @else {
          <ng-container>
            <div class="wen-location-selector-result-group-label" portal-caption>{{ 'CHANNEL_POST_LOCATION_SELECTOR_NEARBY_LABEL' | translate }}</div>
            @if (locationPermission | async) {
              <wen-list-item class="wen-list-item-auto-height" [justifyInfoContent]="'center'" (click)="selectCurrentLocation()">
                <wen-icon class="wen-icon-huge wen-icon-circular" [wenVariant]="'primaryFlat'" wen-list-item-icon iconName="current_location"></wen-icon>
                <span wen-list-item-title>{{ 'CHANNEL_POST_LOCATION_SELECTOR_ACTUAL_POSITION_LABEL' | translate }}</span>
              </wen-list-item>
            } @else {
              <wen-list-item class="wen-list-item-auto-height" [justifyInfoContent]="'center'" (click)="requestDeviceLocation()">
                <wen-icon class="wen-icon-huge wen-icon-circular" [wenVariant]="'primaryFlat'" wen-list-item-icon iconName="location"></wen-icon>
                <span wen-list-item-title>{{ 'CHANNEL_POST_LOCATION_SELECTOR_USE_POSITION_LABEL' | translate }}</span>
              </wen-list-item>
            }
            @for (userLocation of userLocations$ | async; track userLocation) {
              <wen-location-list-item [model]="userLocation" (click)="updateValue(userLocation)" (prefillActionClicked)="prefillLocation($event)"></wen-location-list-item>
            }
            @if (userHomeLocation$ | async; as userHomeLocation) {
              <div class="wen-location-selector-result-group-label" portal-caption>{{ 'CHANNEL_POST_LOCATION_SELECTOR_HOME_LABEL' | translate }}</div>
              <wen-location-list-item [model]="userHomeLocation" (click)="updateValue(userHomeLocation)" (prefillActionClicked)="prefillLocation($event)"></wen-location-list-item>
            }
          </ng-container>
        }
        @if (searchHistory$ | async; as searchHistory) {
          @if (searchHistory.length) {
            <div class="wen-location-selector-result-group-label" portal-caption>{{ 'CHANNEL_POST_LOCATION_SELECTOR_LOCATION_HISTORY_LABEL' | translate }}</div>
            @for (embedLocation of searchHistory; track embedLocation) {
              <wen-location-list-item [model]="embedLocation" (click)="updateValue(embedLocation)" (prefillActionClicked)="prefillLocation($event)"></wen-location-list-item>
            }
          }
        }
      </div>
    } @else {
      <div class="wen-location-selector-empty-results-wrapper">
        <img class="wen-location-selector-empty-image" src="/assets/wen-widget/image/pic-empty-location.svg" alt="">
        <ng-container>
          <div class="wen-location-selector-empty-description" portal-title>
            {{ 'CHANNEL_POST_LOCATION_SELECTOR_EMPTY_DESCRIPTION' | translate }}
          </div>
          <wen-button class="wen-location-selector-use-location-button wen-button-rounded"
            [wenVariant]="'primary'"
            (click)="requestDeviceLocation()">
            {{ 'CHANNEL_POST_LOCATION_SELECTOR_USE_POSITION_LABEL' | translate }}
          </wen-button>
        </ng-container>
      </div>
    }
  </div>



