@if (roomHeader$ | async; as roomHeader) {
  <div class="wen-chat-view-desktop-header-content" (click)="openDetail()">
    <wen-active-item-image-view></wen-active-item-image-view>
    <div class="wen-chat-view-desktop-header-info">
      <div class="wen-chat-view-desktop-header-info-title">
        <h2>{{ roomHeader.title }}</h2>
        @if (roomHeader.isMuted) {
          <wen-icon class="wen-icon-small" [iconName]="'mute'" [color]="'#767676'"></wen-icon>
        }
      </div>
      <h3 portal-body-2>{{ roomHeader?.members }}</h3>
    </div>
  </div>
}
