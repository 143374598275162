@if (datasource.userProfile$ | async; as userProfile) {
  <div class="wen-user-personal-account-settings-content">
      <div menuEntryLayoutGroup>
        <wen-menu-entry [disableArrow]="true" [wen-test-id]="'wen-user-personal-account-settings-PHONE'">
          <span menuEntryTitle>{{ 'USER_PROFILE_FORM_CHANGE_PHONE' | translate }}</span>
          <span menuEntryDescription class="wen-user-personal-account-settings-phone-description">{{ userProfile.identifier }}</span>
        </wen-menu-entry>
        <wen-menu-entry (click)="navigateToEmailSettings()" [wen-test-id]="'wen-user-personal-account-settings-EMAIL'">
          <span menuEntryTitle>{{ 'USER_PROFILE_FORM_CHANGE_EMAIL' | translate }}</span>
          <span menuEntryDescription class="wen-user-personal-account-settings-email-description">
            <div class="wen-user-personal-account-settings-email-description-text">
              @if (enableEmailVerification$ | async) {
                @if (!userProfile.emailVerified && userProfile.email) {
                  <wen-icon class="wen-icon-small"
                  iconName="error_icon" wen-primary-color></wen-icon>
                }
              }
              <span>{{ userProfile.email }}</span>
            </div>
          </span>
        </wen-menu-entry>
        @if (enablePasswordChange$ | async) {
          <wen-menu-entry (click)="navigateToPasswordEdit()" [wen-test-id]="'wen-user-personal-account-settings-PASSWORD'">
            <span menuEntryTitle>{{ 'USER_PROFILE_PERSONAL_ACCOUNT_SETTINGS_PASSWORD_MENU_LABEL' | translate }}</span>
            <span menuEntryDescription wen-primary-color>{{ 'USER_PROFILE_PERSONAL_ACCOUNT_SETTINGS_PASSWORD_MENU_EDIT_ACTION' | translate }}</span>
          </wen-menu-entry>
        }
      </div>
  </div>
}
