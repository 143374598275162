import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { WenNavigationHelper } from '../navigation/types';
import { NetworkLoadedGuard } from '../network/network-loaded-guard';
import { ExclusiveInviteHelper } from '../util/exclusive-invite-helper';
import { AuthStateValidator } from './auth-state-validator.service';
import { WenOAuthService } from './wen-oauth.service';

export const authGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuard).canActivateChild(route, state);
};

@Injectable()
export class AuthGuard {

  constructor(
    private authStateValidator: AuthStateValidator,
    private navigationHelper: WenNavigationHelper,
    private networkLoadedGuard: NetworkLoadedGuard,
    private exclusiveInviteHelper: ExclusiveInviteHelper,
    private oAuthService: WenOAuthService,
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authStateValidator.validateAuthState().pipe(
      switchMap((userData) => {
        const currentUrl = state.url;
        const isCodeDialogNeeded = this.exclusiveInviteHelper.isCodeDialogNeeded(route?.data?.routeId);
        if (isCodeDialogNeeded) {
          this.navigationHelper.navigateToExclusiveInvite(currentUrl);
          return of(false);
        }
        return this.networkLoadedGuard.canActivateChild(route, state).pipe(
          first(),
          map(() => Boolean(userData))
        );
      }),
      catchError(() => {
        const redirectUrl = state.url;
        return this.oAuthService.initCodeFlow({ redirectAfterLogin: redirectUrl }).pipe(
          map(() => false)
        );
      })
    );
  }

}
