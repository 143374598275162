<h1 class="screenreader-only">{{'HOME_PAGE_WELCOME_TITLE' | translate }} {{'HOME_PAGE_WELCOME_TITLE_APP' | translate }}</h1>
<wen-adaptive-background [backgroundStyle]="'flat'" [desktopWidth]="470">
  <div wen-adaptive-background-content class="wen-onboarding-wrapper" (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
    <ng-lottie containerClass="wen-onboarding-lottie-player" [options]="options" [styles]="styles" aria-hidden="true"
      (animationCreated)="animationCreated($event)" (domLoaded)="domLoaded()" (enterFrame)="enterFrame($event)">
    </ng-lottie>
    @if (controlsVisible) {
      <div @controlsEnter class="wen-onboarding-controls">
        <div class="wen-onboarding-controls-text">
          @if (currentSegment > 1) {
            <div @fadeInOut class="wen-onboarding-controls-logo-curtain"></div>
          }
          @if (currentSegment === 1) {
            <div @fadeInOut class="wen-onboarding-controls-text-content">
              <ng-container *ngTemplateOutlet="startText"></ng-container>
            </div>
          }
          @if (currentSegment === 2) {
            <div @fadeInOut class="wen-onboarding-controls-text-content">
              <ng-container *ngTemplateOutlet="fairDigitalText"></ng-container>
            </div>
          }
          @if (currentSegment === 3) {
            <div @fadeInOut class="wen-onboarding-controls-text-content">
              <ng-container *ngTemplateOutlet="channelsText"></ng-container>
            </div>
          }
          @if (currentSegment === 4) {
            <div @fadeInOut class="wen-onboarding-controls-text-content">
              <ng-container *ngTemplateOutlet="addinsText"></ng-container>
            </div>
          }
        </div>
        <div class="wen-onboarding-controls-skip-wrapper">
          @if (controlsVisible) {
            <wen-rounded-button transparent (click)="goToWenAuthPage()">{{
            'ONBOARDING_SKIP' | translate }}</wen-rounded-button>
          }
        </div>
        <div class="wen-onboarding-controls-buttons-wrapper">
          <div class="wen-onboarding-controls-buttons">
            <wen-rounded-button [style.visibility]="canGoBack ? 'visible' : 'hidden'" background (click)="playSegment(-1)">
              {{ 'ONBOARDING_BACK' | translate }}
            </wen-rounded-button>
            <wen-onboarding-progress [currentProgress]="currentSegment - 1" [segments]="segments.length - 1">
            </wen-onboarding-progress>
            <wen-rounded-button (click)="playSegment(1)">
              {{ (!isLastSegment ? 'ONBOARDING_MORE' : 'ONBOARDING_HERE_WE_GO') | translate }}
            </wen-rounded-button>
          </div>
        </div>
      </div>
    }
  </div>
</wen-adaptive-background>

<ng-template #startText>
  <div class="wen-onboarding-start-header" [innerHtml]="'&nbsp'" wen-header-huge></div>
  <h2 portal-headline [innerHtml]="'ONBOARDING_START_TEXT' | translate"></h2>
</ng-template>
<ng-template #fairDigitalText>
  <h2 class="wen-onboarding-fair-digital-header" wen-header-huge [innerHtml]="'ONBOARDING_FAIR_DIGITAL_HEADER' | translate"></h2>
  <h3 portal-headline [innerHtml]="'ONBOARDING_FAIR_DIGITAL_TEXT' | translate"></h3>
</ng-template>
<ng-template #channelsText>
  <h2 class="wen-onboarding-channels-header" wen-header-huge [innerHtml]="'ONBOARDING_CHANNELS_HEADER' | translate"></h2>
  <h3 portal-headline [innerHtml]="'ONBOARDING_CHANNELS_TEXT' | translate"></h3>
  <div class="wen-onboarding-channels-caption">
    <div portal-caption class="wen-onboarding-channels-disclaimer">
      {{ 'ONBOARDING_CHANNELS_DISCLAIMER' | translate }}
      <a portal-caption (click)="goToPrivacyPolicyPage()">{{'USER_PROFILE_PRIVACY_POLICY' | translate}}</a>.
    </div>
  </div>
</ng-template>
<ng-template #addinsText>
  <h2 class="wen-onboarding-channels-header" wen-header-huge [innerHtml]="'ONBOARDING_ADDINS_HEADER' | translate"></h2>
  <h3 portal-headline [innerHtml]="'ONBOARDING_ADDINS_TEXT' | translate"></h3>
</ng-template>
