import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig, BACKEND_API_CONFIG, BackendApiConfig, SocketApiExtensionsModule, SocketIoService, WE_LOCAL_URL, WeLocalImageHelper } from '@portal/wen-backend-api';
import { DownloaderService, E2E_MODE_CONFIG, LinkOpener, OverlayManager, PollManager, ReactionHandler, WenSnackbarOpener } from '@portal/wen-components';
import { WenNativeApi, WenNativeApiModule } from '@portal/wen-native-api';
import { STORE_PERSISTENCE_CONFIG, StorePersistenceDataSerializer } from '@portal/wen-store-persistence';
import { WEN_TRACER_CONFIG, WenTracerModule } from '@portal/wen-tracer';
import { WenLanguageService } from '@portal/wen-translation';
import { DateTimeProvider, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ChannelRestrictionsLoadedGuard } from '../../core/guards/channel-restrictions-loaded-guard';
import { OwnProfileGuard } from '../../core/guards/own-profile-guard';
import { SystemPrivilegesLoadedGuard } from '../../core/guards/system-privileges-loaded.guard';
import { UserProfileActivatorGuard } from '../../core/guards/user-profile-activator-guard';
import { UserProfileLoadedGuard } from '../../core/guards/user-profile-loaded-guard';
import { AppConfigurationProvider } from '../../core/services/configuration/app-confguration';
import { FeatureEnablementService } from '../../core/services/configuration/feature-enablement';
import { NativeConfigurationService } from '../../core/services/configuration/native-configuration';
import { CONNECTION_MONITOR_PROVIDERS } from '../../core/services/connection-monitors/connection-monitor.providers';
import { ClientCustomizationService } from '../../core/services/customization/client-customization-service';
import { ConnectionErrorHandler } from '../../core/services/error-handlers/connection-error-handler';
import { SocketErrorHandler } from '../../core/services/error-handlers/socket-error-handler';
import { FeatureFlagHandler } from '../../core/services/feature-flags/feature-flag-handler';
import { ForwardService } from '../../core/services/forward/forward.service';
import { LocationService } from '../../core/services/location/location.service';
import { locationServiceFactory } from '../../core/services/location/provider';
import { OpenUrlWithNavigationHelperService } from '../../core/services/markdown-text/link-aware-service';
import { downloadServiceFactory } from '../../core/services/media/download.provider';
import { MediaSettingsService } from '../../core/services/media/media-settings.service';
import { BackNavigationHelper } from '../../core/services/navigation/back-navigation/back-navigation-helper';
import { DeepLinkHandler } from '../../core/services/navigation/deep-link/deep-link-handler';
import { SsoDeepLinkHandler } from '../../core/services/navigation/sso-deep-link-handler';
import { StoreNavigator } from '../../core/services/navigation/store-navigator';
import { WenNavigationHelper } from '../../core/services/navigation/types';
import { WEN_APP_STORE_PERSISTENCE_CONFIG } from '../../core/services/persistence/app-store-persistence-config';
import { AppStorePersistenceDataSerializer } from '../../core/services/persistence/app-store-persistence-data-serializer';
import { PollService } from '../../core/services/polling/poll-service';
import { PollViewStateHandler } from '../../core/services/polling/poll-view-state-handler';
import { nativeAppProviders } from '../../core/services/react-native-connector/native-app.providers';
import { ReactionService } from '../../core/services/reactions/reaction.service';
import { SmartDesignApiModule } from '../../core/services/smartdesign/smartdesing-api.module';
import { ChannelEventOrchestrator } from '../../core/services/socket-io/helpers/channel-event-orchestrator';
import { ChatEventOrchestrator } from '../../core/services/socket-io/helpers/chat-event-orchestrator';
import { SocketIoAuthValidator } from '../../core/services/socket-io/hooks/socket-io-auth-validator';
import { SocketIoUpstreamGuard } from '../../core/services/socket-io/hooks/socket-io-upstream-guard';
import { SocketIoServiceImpl } from '../../core/services/socket-io/socket-io-service';
import { WenStorageService } from '../../core/services/storage/wen-storage.service';
import { AuthStateValidator } from '../../core/services/user-management/auth-state-validator.service';
import { AuthTracer } from '../../core/services/user-management/auth-tracer';
import { OauthUrlOpener } from '../../core/services/user-management/oauth-url-opener';
import { OauthDateTimeProvider } from '../../core/services/user-management/oauth/oauth-datettime-provider';
import { WenOAuthService } from '../../core/services/user-management/wen-oauth.service';
import { BrowserInfo } from '../../core/services/util/browser-info';
import { ClipboardService } from '../../core/services/util/clipboard.service';
import { DataContextHelper } from '../../core/services/util/data-context-helper';
import { ExclusiveInviteHelper } from '../../core/services/util/exclusive-invite-helper';
import { HeaderDataHelper } from '../../core/services/util/header-data-helper';
import { NetworkClientConfigService } from '../../core/services/util/network-client-config-service';
import { WenColorService } from '../../core/services/util/wen-color.service';
import { WenIconRegistry } from '../../core/services/util/wen-icon-registry';
import { YellowMapMapService } from '../../core/services/yellowmap/yellowmap-map-service';
import { AuthEffects } from '../../core/store/auth/auth.effects';
import { authReducerFactory } from '../../core/store/auth/auth.reducers';
import { authFeatureKey } from '../../core/store/auth/constants';
import { AddChatUsersSearchMassDataModule } from '../../core/store/chat/mass-data/add-chat-users-search-mass-data-config/add-chat-users-search-mass-data.module';
import { CreateChatSearchMassDataModule } from '../../core/store/chat/mass-data/create-chat-search-mass-data-config/create-chat-search-mass-data.module';
import { ContactsMassDataModule } from '../../core/store/contacts/contacts-mass-data-config/contacts-mass-data.module';
import { ContactsSearchMassDataModule } from '../../core/store/contacts/contacts-search-mass-data-config/contacts-search-mass-data.module';
import { PendingContactsMassDataModule } from '../../core/store/contacts/pending-contacts-mass-data-config/pending-contacts-mass-data.module';
import { CommentListDataResolver } from '../../shared/components/comments/resolvers/comment-list-data.resolver';
import { EmailVerificationService } from '../../shared/feature-specific/email-verification/email-verification.service';
import { FormStoreMediator } from '../../shared/form-store/form-store-mediator';
import { LanguageService } from '../../shared/services/language.service';
import { UnsavedChangesListener } from '../../shared/services/unsaved-changes-listener';
import { AppCreateGuard } from '../../views/apps/app-detail/components/app-edit/guards/app-create.guard';
import { AppDeletedGuard } from '../../views/apps/app-detail/components/app-edit/guards/app-deleted.guard';
import { AppDetailLoadedGuard } from '../../views/apps/app-detail/components/app-edit/guards/app-detail-loaded.guard';
import { DeletedAppHelper } from '../../views/apps/app-detail/components/app-edit/providers/deleted-app-helper';
import { AppEditGuard } from '../../views/apps/common/guards/app-edit.guard';
import { ChannelConfigurationResolver } from '../../views/channel/channel-detail/components/channel-settings/resolvers/channel-configuration.resolver';
import { DiscoverChannelListDataResolver } from '../../views/channel/channel-list/providers/discovery-channel-list-data.resolver';
import { ChannelMessageDetailsLoadedGuard } from '../../views/channel/channel-message-detail/guards/channel-message-detail.guard';
import { ScheduledMessageResolver } from '../../views/channel/channel-schedules/providers/scheduled-messages.resolver';
import { ChannelSubscriberListGuard } from '../../views/channel/channel-subscriber-list/guards/channel-subscriber-list.guard';
import { ChannelAddAdminsGuard } from '../../views/channel/channel-view/components/channel-view/guards/channel-add-admins.guard';
import { ChannelCreateGuard } from '../../views/channel/channel-view/components/channel-view/guards/channel-create.guard';
import { ChannelDeletedGuard } from '../../views/channel/channel-view/components/channel-view/guards/channel-deleted.guard';
import { ChannelListAdminsGuard } from '../../views/channel/channel-view/components/channel-view/guards/channel-list-admins.guard';
import { ChannelListContentProvidersGuard } from '../../views/channel/channel-view/components/channel-view/guards/channel-list-content-providers.guard';
import { JoinRequestDeniedGuard } from '../../views/channel/channel-view/components/channel-view/guards/join-request-denied.guard';
import { JoinRequestHandlingGuard } from '../../views/channel/channel-view/components/channel-view/guards/join-request-handling.guard';
import { ChannelConfigurationProvider } from '../../views/channel/common/providers/channel-configuration';
import { ChannelElevatedPrivilegeMemberListResolver } from '../../views/channel/common/providers/channel-elevated-privilege-member-list-data.resolver';
import { ChannelSubscriberListResolver } from '../../views/channel/common/providers/channel-subscriber-list-data.resolver';
import { inviteHandlerFactory } from '../../views/invite/invite-service/provider';
import { InviteHandler } from '../../views/invite/invite-service/types';
import { apiInitializer } from './app-initializers/api-initializer';
import { connectionErrorHandlerInitializer, socketErrorHandlerInitializer } from './app-initializers/error-handler-initializers';
import { iconConfigInitializer } from './app-initializers/icon-config-initializer';
import { tracerAppInitializer } from './app-initializers/tracer-app-initializer';
import { AuthInterceptor } from './auth-interceptor';
import { MediaInterceptor } from './media-interceptor';
import { APP_NATIVE_API_CONFIG_PROVIDER } from './native-api-config';
import { WenOauthStorage } from './oauth-storage';
import { APP_CONFIG, AUTH_CLIENT_CONFIG, AuthClientConfig, CONTACT_INFO_CONFIG, ContactInfoConfig, DATA_PROTECTION_CONFIG, DEBUG_OPTIONS_CONFIG, DataProtectionConfig, DebugOptions, EXCLUSIVE_INVITE_CONFIG, ExclusiveInviteConfig, HOME_COVER_IMAGE_CONFIG, HomeCoverImageConfig, IMAGE_PROXY_BASE_URL, NATIVE_CONFIG, NativeConfig, ONBOARDING_CONFIG, OnboardingConfig, TEXT_TO_SPEECH_CONFIG, TextToSpeechConfig, TracerConfig, VERSION_INFO, VersionInfo, WALLET_CONFIG, WEATHER_CONFIG, WalletConfig, WeatherConfig, YELLOWMAP_CONFIG, YellowMapConfig } from './tokens';

export interface WenApiConfig {
  backendConfig: BackendApiConfig;
  oauthConfig: AuthClientConfig;
  weLocalBaseUrl: string;
  isE2EMode?: boolean;
  appConfig: AppConfig;
  onBoardingConfig?: OnboardingConfig;
  dataProtectionConfig?: DataProtectionConfig;
  contactInfoConfig?: ContactInfoConfig;
  yellowMapConfig?: YellowMapConfig;
  imageProxyBaseUrl?: string;
  nativeConfig?: NativeConfig;
  debugOptions?: DebugOptions;
  versionInfo?: VersionInfo;
  exclusiveInviteConfig?: ExclusiveInviteConfig;
  weatherConfig?: WeatherConfig;
  textToSpeechConfig?: TextToSpeechConfig;
  homeCoverImageConfig?: HomeCoverImageConfig;
  walletConfig?: WalletConfig;
  tracerConfig?: TracerConfig;
}

const SOCKET_PROVIDERS = [
  SocketErrorHandler,
  {
    provide: SocketIoService,
    useClass: SocketIoServiceImpl
  },
  AuthStateValidator,
  SocketIoUpstreamGuard,
  SocketIoAuthValidator,
  ChannelEventOrchestrator,
  ChatEventOrchestrator,
];

const AUTH_PROVIDERS = [
  WenOAuthService,
  OauthDateTimeProvider,
  {
    provide: DateTimeProvider,
    useClass: OauthDateTimeProvider,
  },
  AuthTracer,
  OauthUrlOpener,
  WenOauthStorage,
  { provide: OAuthStorage, useClass: WenOauthStorage },
];

const GLOBAL_PROVIDERS: Provider[] = [
  ...SOCKET_PROVIDERS,
  ...CONNECTION_MONITOR_PROVIDERS,
  ...AUTH_PROVIDERS,
  APP_NATIVE_API_CONFIG_PROVIDER,
  WenIconRegistry,
  WenColorService,
  WeLocalImageHelper,
  WenStorageService,
  AppConfigurationProvider,
  FeatureEnablementService,
  NativeConfigurationService,
  DeepLinkHandler,
  SsoDeepLinkHandler,
  BackNavigationHelper,
  StoreNavigator,
  FormStoreMediator,
  DataContextHelper,
  HeaderDataHelper,
  EmailVerificationService,
  UserProfileActivatorGuard,
  SystemPrivilegesLoadedGuard,
  UserProfileLoadedGuard,
  OwnProfileGuard,
  ChannelRestrictionsLoadedGuard,
  ChannelMessageDetailsLoadedGuard,
  JoinRequestDeniedGuard,
  ChannelDeletedGuard,
  ChannelCreateGuard,
  ChannelListAdminsGuard,
  ChannelListContentProvidersGuard,
  ChannelAddAdminsGuard,
  AppCreateGuard,
  JoinRequestHandlingGuard,
  AppCreateGuard,
  AppEditGuard,
  AppDeletedGuard,
  AppDetailLoadedGuard,
  MediaSettingsService,
  ...nativeAppProviders,
  PollViewStateHandler,
  PollService,
  { provide: PollManager, useClass: PollService },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MediaInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  ReactionService,
  { provide: ReactionHandler, useExisting: ReactionService },
  OpenUrlWithNavigationHelperService,
  { provide: LinkOpener, useExisting: OpenUrlWithNavigationHelperService },
  BrowserInfo,
  CommentListDataResolver,
  ScheduledMessageResolver,
  DeletedAppHelper,
  ChannelConfigurationProvider,
  UnsavedChangesListener,
  DiscoverChannelListDataResolver,
  ChannelSubscriberListResolver,
  ChannelElevatedPrivilegeMemberListResolver,
  ChannelConfigurationResolver,
  ChannelSubscriberListGuard,
  {
    provide: LocationService,
    useFactory: locationServiceFactory,
    deps: [WenNativeApi, SocketIoService, OverlayManager, WenSnackbarOpener, TranslateService, FeatureEnablementService]
  },
  YellowMapMapService,
  ClientCustomizationService,
  ExclusiveInviteHelper,
  {
    provide: DownloaderService,
    useFactory: downloadServiceFactory,
    deps: [WenNativeApi, HttpClient, WenSnackbarOpener, TranslateService]
  },
  ClipboardService,
  ForwardService,
  NetworkClientConfigService,
  {
    provide: InviteHandler,
    useFactory: inviteHandlerFactory,
    deps: [
      FeatureEnablementService, WenNativeApi,
      WenNavigationHelper, TranslateService,
      SocketIoService, WenSnackbarOpener,
      EXCLUSIVE_INVITE_CONFIG
    ]
  },
  ConnectionErrorHandler,
  FeatureFlagHandler,
  LanguageService,
  {
    provide: WenLanguageService,
    useExisting: LanguageService
  }
];

const MASS_DATA_MODULES = [
  ContactsMassDataModule,
  ContactsSearchMassDataModule,
  PendingContactsMassDataModule,
  CreateChatSearchMassDataModule,
  AddChatUsersSearchMassDataModule,
];

@NgModule({
  imports: [
    ...MASS_DATA_MODULES,
    OAuthModule.forRoot(),
    StoreModule.forFeature(authFeatureKey, authReducerFactory),
    EffectsModule.forFeature([AuthEffects]),
    SocketApiExtensionsModule,
    WenNativeApiModule,
    WenTracerModule,
    SmartDesignApiModule,
  ],
  providers: [
    ...GLOBAL_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: apiInitializer,
      multi: true,
      deps: [WenLanguageService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: tracerAppInitializer,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: socketErrorHandlerInitializer,
      multi: true,
      deps: [SocketErrorHandler]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: connectionErrorHandlerInitializer,
      multi: true,
      deps: [ConnectionErrorHandler]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: iconConfigInitializer,
      multi: true
    },
    {
      provide: StorePersistenceDataSerializer,
      useClass: AppStorePersistenceDataSerializer
    }
  ],
  exports: [
    OAuthModule
  ]
})
export class WenApiModule {

  static forRoot({
    backendConfig, oauthConfig, isE2EMode, appConfig, weLocalBaseUrl, onBoardingConfig, dataProtectionConfig, yellowMapConfig, nativeConfig,
    versionInfo, tracerConfig, debugOptions, imageProxyBaseUrl, contactInfoConfig, exclusiveInviteConfig, weatherConfig, textToSpeechConfig,
    homeCoverImageConfig, walletConfig
  }: WenApiConfig): ModuleWithProviders<WenApiModule> {
    return {
      ngModule: WenApiModule,
      providers: [
        { provide: BACKEND_API_CONFIG, useValue: backendConfig },
        { provide: AUTH_CLIENT_CONFIG, useValue: oauthConfig },
        { provide: E2E_MODE_CONFIG, useValue: isE2EMode },
        { provide: APP_CONFIG, useValue: appConfig },
        { provide: WE_LOCAL_URL, useValue: weLocalBaseUrl },
        { provide: ONBOARDING_CONFIG, useValue: onBoardingConfig },
        { provide: DATA_PROTECTION_CONFIG, useValue: dataProtectionConfig },
        { provide: YELLOWMAP_CONFIG, useValue: yellowMapConfig },
        { provide: NATIVE_CONFIG, useValue: nativeConfig },
        { provide: IMAGE_PROXY_BASE_URL, useValue: imageProxyBaseUrl },
        { provide: DEBUG_OPTIONS_CONFIG, useValue: debugOptions },
        { provide: VERSION_INFO, useValue: versionInfo },
        { provide: CONTACT_INFO_CONFIG, useValue: contactInfoConfig },
        { provide: EXCLUSIVE_INVITE_CONFIG, useValue: exclusiveInviteConfig },
        { provide: WEATHER_CONFIG, useValue: weatherConfig },
        { provide: TEXT_TO_SPEECH_CONFIG, useValue: textToSpeechConfig },
        { provide: HOME_COVER_IMAGE_CONFIG, useValue: homeCoverImageConfig },
        { provide: STORE_PERSISTENCE_CONFIG, useValue: WEN_APP_STORE_PERSISTENCE_CONFIG },
        { provide: WALLET_CONFIG, useValue: walletConfig },
        { provide: WEN_TRACER_CONFIG, useValue: tracerConfig },
      ]
    };
  }
}
