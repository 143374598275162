import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PassFilterData, WenNavigationHelper } from '../../../core/services/navigation/types';
import { removeFilter } from '../../../core/store/filter/filter.actions';
import { FilterEntityType } from '../../../core/store/filter/models/filter';
import { RootState } from '../../../core/store/root/public-api';
import { SectionService } from '../../../core/store/sections/sections.facade';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelSectionComponent } from './components/channel-section/channel-section.component';
import { ScrollStateModule } from '../../../shared/directives/scroll-state/scroll-state.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IsDesktopViewDirective } from '@portal/wen-components';

@Component({
  selector: 'wen-channel-explore',
  templateUrl: './channel-explore.component.html',
  styleUrls: ['./channel-explore.component.scss'],
  imports: [
    TranslateModule,
    SharedModule,
    ChannelSectionComponent,
    ScrollingModule,
    ScrollStateModule,
    IsDesktopViewDirective
  ],
  standalone: true
})
export class ChannelExploreComponent implements OnInit {

  sections$ = this.sectionService.getAvailableSectionsBy(FilterEntityType.CHANNEL_LISTS);

  constructor(
    private navigationHelper: WenNavigationHelper,
    private sectionService: SectionService,
    private store: Store<RootState>,
  ){}

  ngOnInit(): void {
    this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.CHANNEL_LISTS }));
  }

  navigateToChannelDiscovery(filterData?: PassFilterData){
    this.navigationHelper.navigateToChannelDiscovery(filterData);
  }

}
