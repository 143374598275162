import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ChannelType } from '@portal/wen-backend-api';
import { Observable, map, withLatestFrom } from 'rxjs';
import { WenNavigationHelper } from '../../../../../../core/services/navigation/types';
import { selectCurrentChannel, selectSubscribedChannelIds } from '../../../../../../core/store/channel/channel.selectors';
import { SubscribedChannelEntity } from '../../../../../../core/store/channel/channel.state';
import { RootState } from '../../../../../../core/store/root/public-api';


@Component({
  selector: 'wen-channel-view-desktop-header',
  templateUrl: './channel-view-desktop-header.component.html',
  styleUrls: ['./channel-view-desktop-header.component.scss']
})
export class ChannelViewDesktopHeaderComponent implements OnInit {

  @HostBinding('class.wen-channel-view-desktop-header') className = true;

  currentChannel$: Observable<SubscribedChannelEntity>;
  channelType = ChannelType;

  constructor(
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
  ) { }

  ngOnInit(): void {
    this.currentChannel$ = this.store.pipe(
      select(selectCurrentChannel),
      withLatestFrom(this.store.pipe(select(selectSubscribedChannelIds))),
      map(([channel, subscribedChannelIds]) => {
        const isSubscribed = subscribedChannelIds.includes(channel?.id);
        return { ...channel, subscribed: isSubscribed };
      }),
  );}

  openDetail(currentChannelId: string) {
    this.navigationHelper.navigateToChannelDetail(currentChannelId);
  }

}
