<img [src]="imageUrl" alt="" />

<h3 class="wen-create-welcome-title" [innerHTML]="translationKey + '.0' | translate" wen-header-huge></h3>

<wen-create-welcome-markdown class="wen-create-welcome-content" [content]="translationKey + '.1' | translate "></wen-create-welcome-markdown>
<wen-create-welcome-markdown class="wen-create-welcome-content" [content]="translationKey + '.2' | translate "></wen-create-welcome-markdown>
@if (!isPartnerApp) {
  <wen-create-welcome-markdown class="wen-create-welcome-content" [content]="translationKey + '.3' | translate "></wen-create-welcome-markdown>
}

<wen-rounded-button class="wen-create-welcome-button" [wen-test-id]="'wen-create-welcome-button'" (click)="navigate()">
  {{ translationKey + '.4' | translate }}
</wen-rounded-button>
