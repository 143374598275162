@if(listItem; as model) {
  <wen-list-item
    [showAfter]="true"
    [selected]="listItem.selected"
    justifyInfoContent="center"
    (press)="openContextMenu(model)"
  >
    <wen-circle-image-view
      wen-list-item-icon
      [imageUrl]="model.imageUrl"
      [placeholderText]="model.title"
      shape="circle"
    ></wen-circle-image-view>
    <div wen-list-item-title>{{ model.title }}</div>
    @if (model?.muteIcon) {
      <wen-icon class="wen-icon-small" wen-list-item-title-suffixicon [iconName]="model.muteIcon" [color]="'#767676'"></wen-icon>
    }
    @if (model?.pinTimestamp) {
      <wen-icon class="wen-icon-small" wen-list-item-title-suffixicon  iconName="pin" color="#767676"></wen-icon>
    }
    <div wen-list-item-description class="wen-chat-list-item-description">
      <wen-message-status-indicator *isLoggedInUser="model?.insertUser?.id; else otherUserTemplate"
        [messageStatus]="model.messageStatus" [iconClassName]="'wen-icon-small'"
        class="wen-message-status-indicator">
      </wen-message-status-indicator>
      <ng-template #otherUserTemplate>
        @if (model.roomType === roomType.GROUP && model?.insertUser?.name) {
          <span class="wen-list-item-description" with-colon>{{model.insertUser.name}}</span>
        }
      </ng-template>
      @if (model?.description?.icon) {
        <wen-icon [iconName]="model.description.icon" class="wen-icon-small"></wen-icon>
      }
      <span class="wen-list-item-description" [innerHtml]="model?.description?.text | removeMarkdown : markdownEnabled"></span>
    </div>
    <ng-container wen-list-item-after>
      <div portal-subheading-1 class="wen-list-item-timestamp">{{ model.timestamp | messageDateFormatter }}</div>
      @if (model?.notifications?.count > 0) {
        <wen-notification-count class="wen-notification-count-big" [value]="model.notifications.count" [disabled]="model?.muteIcon">
        </wen-notification-count>
      }
    </ng-container>
  </wen-list-item>
}