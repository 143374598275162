<h1 *isDesktopView class="wen-discover-channel-header">{{ 'CHANNEL_SHOW_ALL' | translate }}</h1>

@if (loadResult$ | async; as loadResult) {
  @if (loadResult.data) {
    @if (isFilteringEnabled) {
      <wen-filter-view [formGroup]="filterGroup">
        <wen-filter-select-component [formControlName]="channelCategoryControlName"
          [defaultFilter]="categoryFilterService.fallbackAllCategoryFilter" filter-element
        [filterEntityType]="filterEntityType" (click)="openChannelCategoryFilterSelector()"></wen-filter-select-component>
      </wen-filter-view>
    }

    @if (loadResult.data.length) {
      <wen-channel-list [channelList]="loadResult.data" [wen-test-id]="'wen-discovery-channel-list'"
      [enableContextMenu]="false"></wen-channel-list>
    } @else {
      @if (loadResult.mode === 'search') {
        <wen-empty-search-result></wen-empty-search-result>
      }
    }
  }
}
