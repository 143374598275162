import { AfterViewInit, Directive, ElementRef, forwardRef, Input, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { ResizeHandlerProvider } from '../../util/resize-handler';

export abstract class ContentBoundaryProvider {
  abstract contentBoundary$: Observable<DOMRect>;
}

export interface MessageContentBoundaryConfig {
  /**
   * Provide a padding value for the content to be substracted from the available width
   */
  paddingCorrection: number;
}

@Directive({
  selector: 'wenContentBoundary, [wenContentBoundary]',
  providers: [
    {
      provide: ContentBoundaryProvider,
      useExisting: forwardRef(() => ContentBoundaryDirective)
    },
    ResizeHandlerProvider
  ]
})
export class ContentBoundaryDirective extends ContentBoundaryProvider implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private contentBoundary = new ReplaySubject<DOMRect>(1);

  @Input('wenContentBoundary') config: MessageContentBoundaryConfig;


  readonly parentBoundary$ = this.contentBoundaryProvider ? this.contentBoundaryProvider.contentBoundary$ : of(undefined);
  readonly contentBoundary$ = this.parentBoundary$.pipe(
    switchMap(parentBoundary => this.contentBoundary.pipe(
      map((boundary) => {
        const { paddingCorrection = 0 } = this.config || {};
        let minWidth = boundary.width;
        if (parentBoundary) {
          minWidth = Math.min(parentBoundary.width, minWidth);
        }
        return {
          ...boundary,
          width: minWidth - paddingCorrection
        };
      }),
      shareReplay(1),
    ))
  );

  constructor(
    private elementRef: ElementRef,
    private resizeHandlerProvider: ResizeHandlerProvider,
    @Optional() @SkipSelf() private contentBoundaryProvider: ContentBoundaryProvider,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.updateContentBoundary();

    const resizeHandler = this.resizeHandlerProvider
      .create(this.elementRef.nativeElement);

    resizeHandler.onResize$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.updateContentBoundary());
  }

  private updateContentBoundary() {
    const element: HTMLElement = this.elementRef.nativeElement;
    const contentArea = element.getBoundingClientRect();
    this.contentBoundary.next(contentArea);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.resizeHandlerProvider.detach();
  }

}
