import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { WenComponentsModule } from '@portal/wen-components';
import { FormProgressModule } from '../../../shared/components/form-progress/form-progress.module';
import { RoundedButtonModule } from '../../../shared/components/rounded-button/rounded-button.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChatViewModule } from '../../chat/chat-view/chat-view.module';
import { CurrentUserInfoComponent } from './components/current-user-info/current-user-info.component';
import { EmbeddedChatComponent } from './components/embedded-chat/embedded-chat.component';
import { InitiateChatComponent } from './components/initiate-chat/initiate-chat.component';
import { InviteChatCreationDialogComponent } from './components/invite-chat-creation-dialog/invite-chat-creation-dialog.component';
import { InviteChatCreationDialogOpener } from './services/invite-chat-creation-dialog-opener';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    WenComponentsModule,
    ReactiveFormsModule,
    PortalFieldModule,
    MatFormFieldModule,
    FormProgressModule,
    SharedModule,
    RouterModule,
    RoundedButtonModule,
    ChatViewModule,
  ],
  declarations: [
    CurrentUserInfoComponent,
    InviteChatCreationDialogComponent,
    InitiateChatComponent,
    EmbeddedChatComponent,
  ],
  providers: [
    InviteChatCreationDialogOpener
  ]
})
export class CrmChatModule { }
