import { animate, query, style, transition, trigger } from '@angular/animations';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, QueryList, ViewChildren } from '@angular/core';
import { PagingReplayDirection, ReactionContext } from '@portal/wen-backend-api';
import { FeedRenderingRegistry, smartDistinctUntilChanged } from '@portal/wen-components';
import { animationFrameScheduler, catchError, combineLatest, delay, distinctUntilChanged, exhaustMap, filter, map, Observable, observeOn, of, pairwise, shareReplay, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { WenNativeApi } from '@portal/wen-native-api';
import { ScrollerWrapper } from '../../../../../core/services/scrolling/scroller-wrapper';
import { SeparatorFactory } from '../../../../directives/directives/separator-factory';
import { MessageBoxContentTemplateDirective } from '../../../message-box/directives/message-box.directives';
import { MessageBoxModel } from '../../../message-box/models/message-box.models';
import { WeFeedCustomItemTemplateDirective } from '../../directives/we-feed-custom-item-template.directives';
import { FeedDatasource } from '../../providers/feed-datasource';
import { FeedLayoutCommunicator } from '../../providers/feed-layout-communicator';
import { FeedLayoutHooks } from '../../providers/feed-layout-hooks';
import { FeedLayoutItemFactory } from '../../providers/feed-layout-item-factory';
import { FeedLayoutMediator } from '../../providers/feed-layout-mediator';
import { ScrollToBottomVisibility } from '../../providers/scroll-to-bottom-button-visibility';
import { BaseWeFeedItem, FeedContextMenuEvent, PAGE_LOAD_OFFSET_TRESHOLD_PX, PageRequestEvent, SCROLLED_TO_BOTTOM_THRESHOLD, WeFeedItemInternal } from './we-feed-model';
import { BaseMessageModel } from '../../../message-box/models/base-message-box.model';
import { DateUtil } from '../../../../../core/common/date/date-util';


const MAXIMUM_MESSAGE_MERGE_MINUTES = 10;

@Component({
  selector: 'wen-we-feed',
  templateUrl: './we-feed.component.html',
  styleUrls: ['./we-feed.component.scss'],
  animations: [
    trigger('fadeOutOnLeave', [
      transition(':leave', [
        query('.wen-separator-primary', [
          style({ opacity: 1 }),
          animate(
            '200ms 1s ease-in-out',
            style({ opacity: 0 })
          )
        ], { optional: true })
      ])
    ])
  ],
  providers: [
    ScrollerWrapper, SeparatorFactory,
    FeedLayoutCommunicator,
    FeedLayoutItemFactory,
    {
      provide: FeedRenderingRegistry,
      useExisting: FeedLayoutCommunicator
    },
    ScrollToBottomVisibility
  ]
})
export class WeFeedComponent implements OnInit, OnDestroy, AfterViewInit {

  private onDestroy$ = new Subject<void>();

  @Input() flowDirection: 'up' | 'down' = 'up';
  @Input() flowStyle: 'linear' | 'alternating' = 'linear';
  @Input() reactionContext: ReactionContext;
  /** The maximum width of the feed in px */
  @HostBinding('style.--wen-feed-max-width.px') @Input() maxWidthPx = 600;
  /** The maximum width of the feed items in relation to the maximum width of the feed in % (0-100) */
  @HostBinding('style.--wen-feed-item-max-width.%') @Input() feedItemMaxWidthPercent = 100;

  @HostBinding('class.we-feed-flow-style-alternating') get alternatingStyle() {
    return this.flowStyle === 'alternating';
  }

  feedItems$: Observable<WeFeedItemInternal[]>;
  hasItems$: Observable<boolean>;
  hasNewMessageLineItem$: Observable<boolean>;
  isScrollToBottomButtonVisible$: Observable<boolean>;

  @ViewChildren(CdkScrollable) public scrollerQuery: QueryList<CdkScrollable>;
  @ContentChild(MessageBoxContentTemplateDirective) boxContent: MessageBoxContentTemplateDirective;
  @ContentChild(WeFeedCustomItemTemplateDirective) customItemContent: WeFeedCustomItemTemplateDirective;

  @Output() scrolledToBottom = new EventEmitter<void>();
  @Output() messageVisible = new EventEmitter<MessageBoxModel>();
  @Output() reactionSelectorClicked = new EventEmitter<FeedContextMenuEvent>();

  constructor(
    public feedDatasource: FeedDatasource,
    public feedLayoutCommunicator: FeedLayoutCommunicator,
    public scrollerWrapper: ScrollerWrapper,
    public scrollToBottomVisibility: ScrollToBottomVisibility,
    public feedLayoutItemFactory: FeedLayoutItemFactory,
    public feedLayoutMediator: FeedLayoutMediator,
    public nativeApi: WenNativeApi,
    private cdr: ChangeDetectorRef,
    @Optional() public feedLayoutHooks: FeedLayoutHooks,
  ) {
  }

  private hasSameAuthor(itemA: BaseWeFeedItem<BaseMessageModel>, itemB: BaseWeFeedItem<BaseMessageModel>) {
    if (!itemA || !itemB) {
      return false;
    }
    const valueA = itemA.value;
    const valueB = itemB.value;
    if (!valueA || !valueB || !('authorId' in valueA) || !('authorId' in valueB) || !valueA.authorId || !valueB.authorId) {
      return false;
    }
    return valueA.authorId === valueB.authorId;
  }

  private isConsecutive(itemA: BaseWeFeedItem<BaseMessageModel>, itemB: BaseWeFeedItem<BaseMessageModel>, threshold: number) {
    if (!itemA || !itemB || threshold < 0) {
      return false;
    }
    const diff = DateUtil.getDiffOfIsoStrings(itemA.value.timestamp, itemB.value.timestamp, ['minutes']).minutes;
    return Math.abs(diff) < threshold;
  }

  private applyExtraProps(items: WeFeedItemInternal[]): WeFeedItemInternal[] {
    const newItems: WeFeedItemInternal[] = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const newItem: WeFeedItemInternal = {
        ...item,
        extraProps: {
          mergeWithPrevious: false,
          mergeWithNext: false,
        }
      };

      if (i === 0) {
        newItems.push(newItem);
        continue;
      }

      const connected = this.hasSameAuthor(item, newItems[i-1])
        && this.isConsecutive(item, newItems[i-1], MAXIMUM_MESSAGE_MERGE_MINUTES);

      newItem.extraProps.mergeWithPrevious = connected;
      newItems.push(newItem);

      newItems[i-1].extraProps.mergeWithNext = connected;
    }
    return newItems;
  }


  ngOnInit(): void {
    const source$ = this.feedDatasource.bindToSource().pipe(
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );

    this.feedItems$ = this.feedLayoutMediator.afterNavigationEnd$.pipe(
      switchMap(() => source$),
      smartDistinctUntilChanged(),
      map(({ items, newMessageLineItemId, scrollReferenceItemId, hasMoreOlder }) => {
        if (this.feedLayoutHooks) {
          const messageIds = items.map((item) => {
            return item.value.referenceId || item.value.messageId;
          });
          this.feedLayoutHooks.beforeMessagesRendered(messageIds);
        }
        const { feedItems, newMessageLineKey } = this.feedLayoutItemFactory.createFeedItems(
          items, newMessageLineItemId, hasMoreOlder
        );
        const keys = feedItems.map(feedItem => feedItem.key);
        const scrollReferenceWithPrecedence = scrollReferenceItemId || newMessageLineKey;
        this.feedLayoutCommunicator.onNewPageReceived(keys, scrollReferenceWithPrecedence);
        return this.applyExtraProps(feedItems);
      }),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );
    this.hasNewMessageLineItem$ = source$.pipe(
      map(({ newMessageLineItemId }) => Boolean(newMessageLineItemId))
    );
    this.hasItems$ = this.feedItems$.pipe(
      switchMap((data) => {
        const delayWhenEmpty = data?.length > 0 ? 0 : 200;
        return of(data).pipe(
          delay(delayWhenEmpty)
        );
      }),
      map(value => Boolean(value?.length)),
      distinctUntilChanged(),
      startWith(true)
    );
    this.isScrollToBottomButtonVisible$ = combineLatest([
      this.hasNewMessageLineItem$,
      this.scrollToBottomVisibility.isVisible$
    ]).pipe(
      observeOn(animationFrameScheduler),
      map(([alwaysShow, visibility]) => {
        return alwaysShow || (visibility && !this.feedLayoutCommunicator.isRenderingInProgress());
      }),
      shareReplay(1)
    );
    this.feedLayoutCommunicator.onItemRendered$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.cdr.detectChanges();
    });
    this.feedLayoutCommunicator.renderingInProgress$.pipe(
      filter(hasActiveRendering => !hasActiveRendering),
      map(() => this.scrollerWrapper.measureScrollOffsets()),
      filter(({ topOffset, bottomOffset }) => topOffset === bottomOffset),
      takeUntil(this.onDestroy$),
    ).subscribe(() => this.scrolledToBottom.emit());
  }

  ngAfterViewInit() {
    const cdkScrollable = this.scrollerQuery.first;
    this.scrollerWrapper.connect(cdkScrollable);
    this.feedLayoutCommunicator.connect(this.flowDirection === 'down');
    this.feedLayoutMediator.initialize(this.scrollerWrapper, this.feedLayoutCommunicator);
    this.scrollToBottomVisibility.initialize();
    cdkScrollable.elementScrolled().pipe(
      map(() => {
        const topOffset = cdkScrollable.measureScrollOffset('top');
        const bottomOffset = cdkScrollable.measureScrollOffset('bottom');
        if (bottomOffset <= SCROLLED_TO_BOTTOM_THRESHOLD) {
          this.scrolledToBottom.emit();
        }
        return {
          topOffset, bottomOffset
        };
      }),
      pairwise(),
      map(([prevOffsets, currOffsets]) => {
        const isUp = prevOffsets.topOffset > currOffsets.topOffset;
        const direction = isUp ? PagingReplayDirection.Up : PagingReplayDirection.Down;
        return { prevOffsets, currOffsets, direction };
      }),
      filter((scrollInfo) => {
        const { currOffsets: { topOffset, bottomOffset }, direction } = scrollInfo;
        if (direction === PagingReplayDirection.Up && topOffset < PAGE_LOAD_OFFSET_TRESHOLD_PX) {
          return true;
        }
        if (direction === PagingReplayDirection.Down && bottomOffset < PAGE_LOAD_OFFSET_TRESHOLD_PX) {
          return true;
        }
        return false;
      }),
      smartDistinctUntilChanged(),
      exhaustMap((scrollInfo) => {
        const { direction } = scrollInfo;
        const event: PageRequestEvent = {
          direction
        };
        return this.feedDatasource.loadNextPage(event).pipe(
          catchError(() => {
            return of(null);
          }),
          filter(response => response?.hasResult),
          map(() => scrollInfo),
        );
      }),
      takeUntil(this.onDestroy$),
      catchError(() => {
        return of(null);
      }) // TODO: Fix cdkScrollable leak when scroll is before onDestroy
    ).subscribe(() => {
      this.feedLayoutCommunicator.fixScrollAndRenderingIssuesForIOS();
    });
  }

  trackByFn(index: number, item: WeFeedItemInternal) {
    return item.key;
  }

  handleAfterViewportChange() {
    this.scrollerWrapper.scrollTo({ bottom: 0 });
  }

  onMessageVisible(message: MessageBoxModel) {
    this.messageVisible.emit(message);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
