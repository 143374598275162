import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ChannelViewPreloaderGuard } from '../../../../common/providers/channel-view-preloader.guard';

export const channelViewGuardFn = (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
): true => {
  const channelViewPreloaderGuard = inject(ChannelViewPreloaderGuard);
  channelViewPreloaderGuard.ensureCurrentChannelPermissionsLoaded();
  return true;
};
