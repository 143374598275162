<div class="wen-contact-list-container" [class.wen-contact-list-filter-active]="containerConnector.isFilteredActive()">
  <div class="wen-contact-list-menu-wrapper">
    @if (!containerConnector.isFilteredActive()) {
      <wen-menu-entry (click)="handleToPendingMenuItemClick()">
        <wen-icon [iconName]="toPendingMenuEntryConfig.iconName" [fillColor]="toPendingMenuEntryConfig.fillColor"
          [color]="'white'" class="wen-icon-medium" menuEntryIcon>
        </wen-icon>
        <span portal-body-1 menuEntryTitle>{{ toPendingMenuEntryConfig.translationKey | translate }}</span>
        @if (openContactRequestCount$ | async; as notificationCount) {
          <ng-container menuEntryDescription>
            @if (notificationCount > 0) {
              <wen-notification-count class="wen-notification-count-big"
              [value]="notificationCount"></wen-notification-count>
            }
          </ng-container>
        }
      </wen-menu-entry>
    }
  </div>
  <div class="wen-contact-list-wrapper">
    <wen-list-mass-data-viewer [datasource]="containerConnector.activeDatasource">
      @if (containerConnector.isFilteredActive()) {
        <ng-container wen-list-mass-data-empty>
          <wen-empty-list src="/assets/wen-widget/image/empty-placeholders/pic-empty-search-result-image.svg"
          [titleLabel]="'NO_SEARCH_RESULT_TEXT'"></wen-empty-list>
        </ng-container>
      }
      @if (!containerConnector.isFilteredActive()) {
        <ng-container wen-list-mass-data-empty>
          <wen-empty-list #normalListEmptyTemplate
            src="/assets/wen-widget/image/empty-placeholders/pic-empty-wen-contacts-list.svg"
          [titleLabel]="'CONTACTS_LIST_EMPTY_HEADER'" [textLabel]="'CONTACTS_LIST_EMPTY_DESCRIPTION'"></wen-empty-list>
        </ng-container>
      }
      <ng-template wen-list-mass-data-item let-listItemData let-index="index">
        @if (containerConnector.isFilteredActive()) {
          @if ((listService.separatorPositions$ | async)?.acceptedIndex === index) {
            <h3
              class="wen-contact-list-group-label">
              {{ "CONTACTS_LIST_SEARCH_FRIEND_GROUP_TITLE" | translate }}
            </h3>
          }
          @if ((listService.separatorPositions$ | async)?.suggestedIndex === index) {
            <h3
              class="wen-contact-list-group-label">
              {{ "CONTACTS_LIST_SEARCH_SUGGESTED_GROUP_TITLE" | translate }}
            </h3>
          }
        }
        @if (!containerConnector.isFilteredActive()) {
          @if (index === 0) {
            <h3 class="wen-contact-list-group-label">
              {{"CONTACTS_LIST_SEARCH_FRIEND_GROUP_TITLE" | translate}}
            </h3>
          }
        }
        <wen-user-list-item [user]="listItemData | toUserListItem" [userNavigable]="listItemData.user.id">
          <div class="wen-contact-user-list-item-actions-after" wen-user-after>
            @if (isNotConnected(listItemData)) {
              <wen-contact-response-action [iconOnly]="true"
              [profileData]="convertToResponseActionData(listItemData)"></wen-contact-response-action>
            }
            @if (chatAllowed(listItemData.user.chatAllowed)) {
              <wen-icon class="wen-icon-huge" (click)="initiateChatWithUser($event, listItemData)"
              [wenVariant]="'secondaryInlined'" iconName="message_inactive"></wen-icon>
            }
          </div>
        </wen-user-list-item>
      </ng-template>
    </wen-list-mass-data-viewer>
  </div>
</div>
