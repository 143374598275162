import { EmbedDTOTypes, isLinkEmbed, isMediaEmbed } from '@portal/wen-backend-api';
import { Thumbnail } from '../components/thumbnail-image/thumbnail-image-model';

export class EmbedThumbnailManager {
  public getEmbedThumbnail(embed: EmbedDTOTypes): Thumbnail {
    if (isMediaEmbed(embed)) {
      return { url: embed.thumbnailUrl, width: embed.width, height: embed.height };
    }
    if (isLinkEmbed(embed)) {
      return embed.thumbnail;
    }
  }
}
