@if (isWeLocalPlayerNeeded) {
  <iframe [wenLazyload]="sourceUrl" allowfullscreen></iframe>
} @else {
  @if (canUseNewVideoPlayer) {
    <div class="wen-embed-overlay" (tap)="onMediaClick()">
      <wen-button class="wen-button-round" wenVariant="icon" iconName="play" iconSize="huge"></wen-button>
    </div>
    <div class="wen-embed-video-wrapper">
      <img
        #image
        class="wen-embed-video"
        [wenLazyload]="thumbnail$ | async"
        [wenLazyloadDisableScaling]="true"
        retry-image-load [alt]="mediaAltText"
        />
    </div>
  } @else {
    <video [poster]="thumbnail$ | async" preload="metadata" controls playsInline [title]="mediaAltText">
      <source [src]="sourceUrl" />
    </video>
  }
}
