import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { CircleImageViewModule, CircleInitialViewModule, WenIconModule, WenTestIdModule } from '@portal/wen-components';
import { InteractButtonModule } from '../components/interact-button/interact-button.module';
import { SubscribedToModule } from '../directives/subscibed-to/subscribed-to.module';
import { ChannelSubscribeButtonComponent } from './components/channel-subscribe-button/channel-subscribe-button.component';
import { ChannelSubscriptionControlsComponent } from './components/channel-subscription-controls/channel-subscription-controls.component';
import { MuteUnmuteChannelButtonComponent } from './components/mute-unmute-channel-button/mute-unmute-channel-button.component';
import { NavigateToChannelButtonComponent } from './components/navigate-to-channel-button/navigate-to-channel-button.component';
import { MessageDateFormatterPipe } from './pipes/message-date-formatter/message-date-formatter.pipe';
import { ChannelMuteService } from './providers/channel-mute.service';
import { MessageDateFormatter } from './providers/message-date-formatter';
import { PinChannelService } from './providers/pin-channel.service';
import { ShareService } from './providers/share.service';

@NgModule({
  imports: [
    CommonModule,
    CircleImageViewModule,
    CircleInitialViewModule,
    SubscribedToModule,
    MatButtonModule,
    WenIconModule,
    InteractButtonModule,
    WenTestIdModule,
    TranslateModule.forChild(),
    MessageDateFormatterPipe
  ],
  declarations: [
    ChannelSubscribeButtonComponent,
    ChannelSubscriptionControlsComponent,
    MuteUnmuteChannelButtonComponent,
    NavigateToChannelButtonComponent,
  ],
  providers: [
    MessageDateFormatter,
    ChannelMuteService,
    ShareService,
    PinChannelService
  ],
  exports: [
    CircleImageViewModule,
    CircleInitialViewModule,
    ChannelSubscribeButtonComponent,
    ChannelSubscriptionControlsComponent,
    MuteUnmuteChannelButtonComponent,
    NavigateToChannelButtonComponent,
  ]
})
export class ChannelSpecificSharedModule { }
