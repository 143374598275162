import { WenLanguage, WenNetwork } from '@portal/wen-backend-api';
import { getVersionNr } from '../utils/version-helper';
import { EnvironmentType } from './environment-type';

export const environment: EnvironmentType = {
  production: true,
  tracerConfig: {
    dsn: 'https://9f70f659f46b3f78c247994be7a243f6@sentry.cas-software.dev/2',
    appEnv: 'wen-dev',
    version: getVersionNr(),
    sentryIntegrations: 'all',
    enableSensitiveDataSending: true
  },
  backendConfig: {
    backendUrl: 'https://websocket-central.development.we.network/',
    mediaServiceBaseUrl: 'https://media-central.development.we.network/',
    network: WenNetwork.WE_NETWORK,
  },
  oauthConfig: {
    postLogoutRedirectUri: '/signin/start',
    clientId: 'wen-1012766a-db47-472a-9279-0d4271bfc2d1',
    issuer: 'https://identity-central.development.we.network',
    tokenEndpoint: 'https://identity-central.development.we.network/oauth/token',
    logoutUrl: 'https://identity-central.development.we.network/forcelogout',
    revocationEndpoint: 'https://identity-central.development.we.network/oauth/token/revoke',
    keepAliveEndpoint: 'https://identity-central.development.we.network/keep-alive',
  },
  weLocalBaseUrl: 'https://staging.wenetwork.prod.welocal.cloud',
  yellowMapConfig: {
    yellowMapUrl: 'https://location.smartmaps.app',
    yellowMapApiKey: 'dXsavGwBsiBmtLMq8+C0Y5iufdUC5/ZoS35Pu8v9Dv0=',
  },
  contactInfoConfig: {
    supportEmail: 'info@we.network',
    waitlistUrl: 'https://www.we.network/anmeldeformular',
    hotline: [
      {
        fieldName: 'Bioland direkt für Erzeuger (Deutschland)',
        phoneNumber: '+49123456789',
      },
      {
        fieldName: 'Bioland-Hotline Südtirol',
        phoneNumber: '+39123456788',
      },
      {
        fieldName: 'Bioland Partner direkt',
        phoneNumber: '+49123456777',
      },
    ],
  },
  exclusiveInviteConfig: {
    inviteUrl: 'https://get.we.network',
  },
  weatherConfig: {
    location: 'Karlsruhe',
    countryCode2Letters: 'DE',
    goToLink: 'https://web1.karlsruhe.de/wetter',
  },
  textToSpeechConfig: {
    textToSpeechUrl: 'https://weread-tts-test.livek8s.ym-it.cas.de',
    textToSpeechApiKey: '3c1a853d7a88dbb9be83df574c601e2c',
  },
  nativeConfig: {
    nativeDeepLink: 'we-network',
    useNativeDialog: false,
    storeInfos: {
      androidPlayStoreId: 'we.network',
      iosAppName: 'we-network',
      iosAppStoreId: '6449700172',
    },
  },
  dataProtectionConfig: {
    manifestEnabled: true,
  },
  appConfig: {
    enablePwa: true,
    enableChat: true,
    enableDevTools: true,
    enableChatDebugMode: false,
    enableEvent: true,
    enableAppStatePersistence: true,
    enableAppBanner: true,
    enableAgb: true,
    enableInviteOnlyNetwork: false,
    enableInviteToNetwork: true,
    enableDisconnectErrorPage: true,
    enableShareMenu: true,
    enableCRMDistributionCancelling: true,
  },
  debugOptions: {
    enableNativeBridgeDebug: true,
    enableAutoRefreshByAssetChanges: false,
  },
  walletConfig: {
    credentialProviderForUnitedKiosk:
      'https://test1-credential-issuer.k8s.united-kiosk.de',
  },
  supportedLanguages: [WenLanguage.GERMAN, WenLanguage.ENGLISH]
};
