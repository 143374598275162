import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormProgressModule } from '../../shared/components/form-progress/form-progress.module';
import { RoundedButtonModule } from '../../shared/components/rounded-button/rounded-button.module';
import { LoginModule } from '../login/login.module';
import { SignInComponent } from './components/signin/sign-in.component';
import { SignInInviteGuard } from './guards/sign-in-invite-guard';
import { SignInInviteDialogOpener } from './services/sign-in-invite-dialog-opener';

@NgModule({
  imports: [
    CommonModule,
    RoundedButtonModule,
    TranslateModule,
    FormProgressModule,
    LoginModule
  ],
  declarations: [
    SignInComponent
  ],
  exports: [
    SignInComponent
  ],
  providers: [
    SignInInviteGuard,
    SignInInviteDialogOpener
  ]
})
export class SignInModule { }
