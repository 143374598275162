import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ChosenOptionRequest, EmbedDTOType, GetPollRequest, PollModel, SocketIoService } from '@portal/wen-backend-api';
import { PollManager } from '@portal/wen-components';
import { merge, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { updateDraftChannelMessage } from '../../store/channel/channel.actions';
import { selectCurrentChannel } from '../../store/channel/channel.selectors';
import { RootState } from '../../store/root/public-api';
import { PollViewState, PollViewStateHandler } from './poll-view-state-handler';

@Injectable()
export class PollService extends PollManager {

  pollUpdates$: Observable<Partial<PollModel>>;

  constructor(
    private socketIOService: SocketIoService,
    private store: Store<RootState>,
    private pollViewStateHandler: PollViewStateHandler,
  ) {
    super();
    this.setupSocketConnections();
  }

  getPoll(pollRequest: GetPollRequest): Observable<PollModel> {
    return this.socketIOService.messageEmbed.poll.get.acknowledgement$(pollRequest).pipe(
      tap(pollModel => this.pollViewStateHandler.add(pollModel))
    );
  }

  chooseOption(selectedOption: ChosenOptionRequest) {
    this.socketIOService.messageEmbed.poll.chooseOption.emit(selectedOption);
  }

  createPoll(newPoll: PollModel): Observable<void> {
    return this.store.pipe(
      select(selectCurrentChannel),
      first(),
      map((channel) => {
        const { id: channelId } = channel;
        const poll: PollModel = {
          channelId,
          multiSelect: newPoll.multiSelect,
          options: newPoll.options,
          question: newPoll.question,
        };
        this.store.dispatch(updateDraftChannelMessage({
          message: {
            contextId: channelId,
            pollEmbed: {
              pollId: null,
              type: EmbedDTOType.POLL,
              poll
            }
          }
        }));
      })
    );
  }

  getPollViewUpdatesFor(id: string): Observable<PollViewState> {
    return this.pollViewStateHandler.getUpdatesFor(id);
  }

  private setupSocketConnections() {
    this.pollUpdates$ = merge(
      this.socketIOService.messageEmbed.poll.update.listen.pipe(map(partialPoll => this.pollViewStateHandler.upsertPoll(partialPoll))),
      this.socketIOService.messageEmbed.poll.chooseOption.listen,
    );
  }
}
