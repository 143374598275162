@if (quote) {
  <div class="wen-quote-border" [ngStyle]="{'border-color': borderColor }"></div>
  <div class="wen-quote-content-wrapper">
    <div class="wen-quote-content">
      <div portal-subheading-1 class="wen-quote-author" [wenTextColorizer]="quote.author">{{ quote.author }}</div>
      <div class="wen-quote-text">
        @if(!quote.isDeleted) {
          <div portal-subheading-1 class="wen-quote-text-section" [innerHTML]="quote.text | removeMarkdown"></div>
        } @else {
          <wen-deleted-indicator [message]="quote.text"></wen-deleted-indicator>
        }
        @if(!quote.text && quote?.embed){
          <wen-embed-description [embed]="quote.embed"></wen-embed-description>
        }
      </div>
    </div>
    @if(quote?.embed) {
      <div class="wen-quote-after">
        @if(embedThumbnail) {
          <wen-thumbnail-image [thumbnail]="embedThumbnail" [roundedEdges]="true" [fixWidth]="50" [fixHeight]="50"></wen-thumbnail-image>
        }
        @if(embedIcon) {
          <wen-icon class="wen-embed-icon " [iconName]="embedIcon"></wen-icon>
        }
      </div>
    }
  </div>
}
