@if (prefixTranslation) {
  <div class="wen-chip-input-search-prefix">
    <span>{{ prefixTranslation | translate }}</span>
  </div>
}
<div class="wen-form-field-wrapper">
  <mat-form-field class="wen-form-field-white">
    <mat-chip-grid #chipGrid class="wen-chip-grid" (change)="onChipGridChange()">
      <div class="mat-chip-list-wrapper">
        @for (item of selectedItems; track item) {
          <mat-chip-row class="wen-chip-row"
            [value]="item"
            (removed)="onItemRemoval(item)">
            @if (item.icon) {
              <img class="wen-chip-avatar" matChipAvatar [src]="item.icon" alt="" />
            }
            <span class="wen-chip-label">{{item.text}}</span>
            <wen-icon iconName="close" matChipRemove class="wen-icon-medium wen-chip-button"></wen-icon>
          </mat-chip-row>
        }
        <input #chipInput
          [disabled]="itemCount >= maxNumberOfSelectableItems"
          [matChipInputFor]="chipGrid"
          [placeholder]="placeholder$ | async | translate"
          (input)="onSearchChange($event.target.value)"
          wenVkInput [wenVkInputKeepFocused]="keepFocused"
          wenIosKeyboardLockTarget
          autocomplete="off"
          />
        </div>
      </mat-chip-grid>
      @if (itemCount > 0) {
        <div class="wen-selected-item-count" portal-subheading-1>
          {{itemCount}} / {{maxNumberOfSelectableItems}}
        </div>
      }
    </mat-form-field>
  </div>
