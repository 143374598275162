import { Component, inject, Input } from '@angular/core';
import { EmbeddedQuoteDTO } from '@portal/wen-backend-api';
import { QuoteData } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { QuoteHandler } from '../../../../../../core/services/util/quote-handler';
import { QuoteService } from '../../../../../../views/chat/chat-view/providers/quote.service';

@Component({
  selector: 'wen-embed-quote',
  templateUrl: 'embed-quote.component.html'
})
export class EmbedQuoteComponent {

  private quoteHandler = inject(QuoteHandler);
  private quoteService = inject(QuoteService);
  quoteItem$: Observable<QuoteData>;

  @Input() set model(value: EmbeddedQuoteDTO) {
    if (value) {
      this.quoteItem$ = this.quoteHandler.retrieveQuote(value.quotedItemId);
    }
  }

  onQuoteClick(item: QuoteData) {
    this.quoteService.scrollToQuote(item);
  }

}
