import { Injectable } from '@angular/core';
import { EmbeddedMedia } from '../../../../../../core/store/channel/channel.state';
import { MessageBoxModel } from '../../../../../../shared/components/message-box/models/message-box.models';
import { MessageBoxCustomizations, MessageBoxLayoutConfig } from '../../../../../../shared/components/message-box/providers/message-box-embed-customizations';
import { MessageModificationState } from '@portal/wen-backend-api';
import { MessageContentBoundaryConfig } from '@portal/wen-components';

@Injectable()
export class ChatMessageCustomizations extends MessageBoxCustomizations {
  handleMediaEmbeds(mediaEmbeds: EmbeddedMedia[], message?: MessageBoxModel): EmbeddedMedia[] {
    return super.noopMediaFilter(mediaEmbeds);
  }
  handleMessageValidation(message?: MessageBoxModel): boolean {
    return message?.state !== MessageModificationState.DELETED && message?.state !== MessageModificationState.ERROR;
  }
  canOpenContextMenu(message?: any): boolean {
    return message.state !== MessageModificationState.DELETED;
  }

  canSwipe(message?: any): boolean {
    return message.state !== MessageModificationState.DELETED && !message.scheduled;
  }

  getClassOverrides(message?: any): { [className: string]: boolean } {
    return {
      'wen-chat-message-box': true,
    };
  }

  getLayoutConfig(message?: any): MessageBoxLayoutConfig {
    return {
      inlineContent: true,
      bubbleTail: true,
      textBelowEmbeds: true,
      footerInformation: true
    };
  }

  getContentBoundaryConfig(message?: any): MessageContentBoundaryConfig {
    return {
      paddingCorrection: 24,
    };
  }
}
