import { WenRoutes } from '../../../frame/routing/types';
import { userProfileViewRoute, userProfileManifestRoute, userProfileFAQRoute, userProfileMarkdownFAQRoute, userProfileComingSoonRoute, userProfileReadRoute, userProfileEditRoute, userProfileLanguageRoute, userProfileHelpRoute, userSettingsRoute, userProfileHotlineRoute, licensesRoute, userProfileEmailSettingsRoute, userProfileChangeEmailRoute, userProfileSettingsRoute, userProfileCredentialsMenuRoute, userProfileCredentialDetails, userProfilePrivacySettingsRoute, otherProfileViewRoute, userPersonalAccountSettingsRoute, userChangePasswordRoute, userChangePasswordVerifyCodeRoute, userProfileLocationSelectorRoute } from './user-profile.routes';

export const singleOutletUserProfileRoutes: WenRoutes = [
  userProfileViewRoute,
  userProfileManifestRoute,
  userProfileFAQRoute,
  userProfileMarkdownFAQRoute,
  userProfileComingSoonRoute,
  userProfileReadRoute,
  userProfileEditRoute,
  userProfileLanguageRoute,
  userProfileHelpRoute,
  userSettingsRoute,
  userPersonalAccountSettingsRoute,
  userProfileHotlineRoute,
  licensesRoute,
  userProfileEmailSettingsRoute,
  userProfileChangeEmailRoute,
  userChangePasswordRoute,
  userChangePasswordVerifyCodeRoute,
  userProfileSettingsRoute,
  userProfileCredentialsMenuRoute,
  userProfileCredentialDetails,
  userProfilePrivacySettingsRoute,
  otherProfileViewRoute,
  userProfileLocationSelectorRoute
];
