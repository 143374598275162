import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ChannelAddAdminModule } from './channel-add-admin/channel-add-admin.module';
import { ChannelAdminListModule } from './channel-admin-list/channel-admin-list.module';
import { ChannelContentProviderListModule } from './channel-content-provider/channel-content-provider-list.module';
import { ChannelDetailModule } from './channel-detail/channel-detail.module';
import { ChannelEditModule } from './channel-edit/channel-edit.module';
import { ChannelExploreComponent } from './channel-explore/channel-explore.component';
import { ChannelFeaturedModule } from './channel-featured-view/channel-featured-view.module';
import { ChannelListModule } from './channel-list/channel-list.module';
import { ChannelMessageDetailModule } from './channel-message-detail/channel-message-detail.module';
import { ChannelPostEditorModule } from './channel-post-editor/channel-post-editor.module';
import { ChannelSchedulesModule } from './channel-schedules/channel-schedules.module';
import { ChannelSubscriberListModule } from './channel-subscriber-list/channel-subscriber-list.module';
import { ChannelViewModule } from './channel-view/channel-view.module';
import { PendingChannelJoinRequestModule } from './pending-channel-join-request/pending-channel-join-request.module';
import { EmbedService } from '../../core/services/util/embed-service';

@NgModule({
  imports: [
    SharedModule,
    ChannelViewModule,
    ChannelPostEditorModule,
    ChannelFeaturedModule,
    ChannelMessageDetailModule,
    ChannelListModule,
    ChannelDetailModule,
    ChannelEditModule,
    PendingChannelJoinRequestModule,
    ChannelAdminListModule,
    ChannelAddAdminModule,
    ChannelSchedulesModule,
    ChannelContentProviderListModule,
    ChannelSubscriberListModule,
    ChannelExploreComponent
  ],
  providers: [
    EmbedService
  ]
})
export class ChannelModule { }
