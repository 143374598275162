import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { generateId } from '@portal/wen-backend-api';
import LazySizes from 'lazysizes';
import { FeedRenderingRegistry } from '../../components/feed/services/feed-rendering-registry';
import { ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ContentBoundaryProvider } from '../content-boundary/content-boundary.directive';
import { FitType, ScaleHelper } from '../../util/scale-helper';
import { ImageUrlTransformator } from '../../pipes/image-url-transform/image-url-transform.pipe';
import { debounceTimeAfterFirst } from '../../util/operators/dbounce-time-after';

export interface LazyLoadParams {
  src: string;
  originalWidth?: number;
  originalHeight?: number;
  maxHeight?: number;
  minHeight?: number;
}

LazySizes.init();

@Directive({
  selector: '[wenLazyload]',
})
export class LazyLoadDirective implements OnInit, OnDestroy {

  private onDestroy = new Subject<void>();

  private params$ = new ReplaySubject<LazyLoadParams>(1);
  private fixWidth$ = new ReplaySubject<number>(1);

  @HostBinding('class.wen-lazy-load') lazyLoadClass = true;

  @Input('wenLazyload')
  set lazyLoadParams(params: LazyLoadParams | string) {
    if (typeof params === 'string') {
      this.params$.next({ src: params });
    } else {
      this.params$.next(params);
    }
  }

  @Input('wenLazyloadFixWidth') set fixWidth(value: number) {
    this.fixWidth$.next(value);
  }
  @Input('wenLazyloadDisableScaling') disableScaling: boolean;
  @Input('wenLazyloadFixHeight') fixHeight: number;

  private renderingProgressId = generateId();
  private scaler: ScaleHelper;

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private urlTransformator: ImageUrlTransformator,
    @Optional() private contentBoundaryProvider: ContentBoundaryProvider,
    @Optional() private feedRenderingRegistry: FeedRenderingRegistry,
  ) {
    this.scaler = new ScaleHelper(element, renderer);
  }

  private updateElement(params: LazyLoadParams) {
    const existingSrc = this.element.nativeElement.attributes.getNamedItem('data-src')?.value;
    let newSrc = params.src;
    const width = parseInt(this.element.nativeElement.style.width, 10);
    if (width && newSrc) {
      newSrc = this.getProperlyScaledUrl(params, width, newSrc);
    }
    if (existingSrc !== newSrc) {
      this.renderer.addClass(this.element.nativeElement, 'lazyload');
      this.renderer.setAttribute(this.element.nativeElement, 'data-src', newSrc);
      const image = this.element.nativeElement as HTMLImageElement;
      if (image?.src) {
        image.src = newSrc;
      }
    }
  }

  private getProperlyScaledUrl(params: LazyLoadParams, width: number, src: string) {
    if (this.fixHeight) {
      const scale = ScaleHelper.computeScale(params.originalWidth, params.originalHeight, width, this.fixHeight, this.fixHeight);
      if (scale.fitType === FitType.TOO_WIDE) {
        return this.urlTransformator.transform(src, { height: scale.height });
      } else {
        return this.urlTransformator.transform(src, { width: scale.width });
      }
    } else {
      return this.urlTransformator.transform(src, { width });
    }
  }

  ngOnInit() {
    if (this.feedRenderingRegistry) {
      this.feedRenderingRegistry.registerRenderingProgress(this.renderingProgressId);
    }
    const contentBoundaryWidth$ = this.contentBoundaryProvider
      ? this.contentBoundaryProvider.contentBoundary$.pipe(
          map(({ width }) => width)
        )
      : of(null);
    const availableWidth$ = this.fixWidth$.pipe(
      startWith(0),
      switchMap(fixWidth => contentBoundaryWidth$.pipe(
        map(width => !!fixWidth ? fixWidth : width)
      )));

    combineLatest([
      availableWidth$,
      this.params$
    ]).pipe(
      filter(([_, params]) => !!params),
      distinctUntilChanged(([prevWidth, prevParams], [currWidth, currParams]) => {
        const sameSrc = prevParams.src === currParams.src;
        const sameSize = prevWidth === currWidth;
        return sameSrc && sameSize;
      }),
      tap(([availableWidth, params]) => {
        const { originalWidth, originalHeight } = params || {};
        let { maxHeight, minHeight } = params || {};
        const hasKnownImageSize = !!originalWidth && !!originalHeight;
        if (!hasKnownImageSize) {
          this.scaler.setElementSize(availableWidth);
        } else if (this.disableScaling) {
          this.scaler.setElementSize(originalWidth, originalHeight);
        } else if (availableWidth) {
          if (this.fixHeight) {
            maxHeight = this.fixHeight;
            minHeight = this.fixHeight;
          }
          this.scaler.scaleElementToWidth(originalWidth, originalHeight, availableWidth, maxHeight, minHeight);
        }
      }),
      debounceTimeAfterFirst(500),
      takeUntil(this.onDestroy)
    ).subscribe(([_, params]) => {
      this.updateElement(params);
      if (this.feedRenderingRegistry) {
        this.feedRenderingRegistry.clearRenderingProgress(this.renderingProgressId);
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
