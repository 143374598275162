import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { HammerModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { FocusFirstInputModule, ImageUrlTransformator, LazyLoadModule, MenuEntryModule, PaletteProvider, ScaleToWidthModule, ScrollToButtonComponent, ThumbnailImageModule, WenComponentsModule, WenDatepickerModule, WenTestIdModule } from '@portal/wen-components';
import { DateUtil } from '../core/common/date/date-util';
import { WenDesktopRouteDataResolver } from '../core/services/navigation/route-data/desktop-route-data-resolver';
import { WenMobileRouteDataResolver } from '../core/services/navigation/route-data/mobile-route-data-resolver';
import { MediaValidator } from '../core/services/util/media-validator';
import { PopupEventHandler } from '../core/services/util/popup-event-handler';
import { ChannelSpecificSharedModule } from './channel-specific/channel-specific.module';
import { MuteUnmuteChatButtonComponent } from './chat-specific/components/mute-unmute-chat-button/mute-unmute-chat-button.component';
import { ChatMuteService } from './chat-specific/providers/chat-mute.service';
import { ActiveItemImageViewModule } from './components/active-item-image-view/active-item-image-view.module';
import { AdaptiveBackgroundModule } from './components/adaptive-background/adaptive-background.module';
import { AppBannerModule } from './components/app-banner/app-banner.module';
import { AppLogoComponent } from './components/app-logo/app-logo.component';
import { ChatSyncModule } from './components/chat-sync/chat-sync.module';
import { ChristmasModule } from './components/christmas/christmas.module';
import { CommentMessageSendModule } from './components/comments/comment-message-send-field/comment-message-send-field.module';
import { CreateWelcomeModule } from './components/create-welcome/create-welcome.module';
import { DataProtectionDialogModule } from './components/data-protection-dialog/data-protection-dialog.module';
import { DetailHeaderModule } from './components/detail-header/detail-header.module';
import { EmbeddedPageViewerModule } from './components/embedded-page-viewer/embedded-page-viewer.module';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { EmptySearchResultComponent } from './components/empty-search-result/empty-search-result.component';
import { ExpandableContainerModule } from './components/expandable-container/expandable-container.module';
import { ExtendedTextEditorModule } from './components/extended-text-editor/extended-text-editor.module';
import { FileDropOverlayComponent } from './components/file-drop-overlay/file-drop-overlay.component';
import { DateRangePickerModule } from './components/filter-view/components/custom-filter-views/date-range-picker/date-range-picker.module';
import { FilterViewModule } from './components/filter-view/filter-view.module';
import { ForwardButtonComponent } from './components/forward-button/forward-button.component';
import { ImageUploadModule } from './components/image-upload/image-upload.module';
import { InteractButtonModule } from './components/interact-button/interact-button.module';
import { ListItemModule } from './components/list-item/list-item.module';
import { EmbedsModule } from './components/message-box/components/embeds/embeds-module';
import { MessageBoxModule } from './components/message-box/message-box.module';
import { MessagePreviewCardModule } from './components/message-preview-card/message-preview-card.module';
import { MessageSendFieldModule } from './components/message-send-field/message-send-field.module';
import { NotificationCountComponent } from './components/notification-count/notification-count.component';
import { PaginatedScrollviewModule } from './components/paginated-scrollview/paginated-scrollview.module';
import { PasswordVisibilityToggleModule } from './components/password-visibility-toggle/password-visibility-toggle.module';
import { LegacyQuoteModule } from './components/quote/quote.module';
import { RefreshBannerModule } from './components/refresh-banner/refresh-banner.module';
import { RoundedButtonModule } from './components/rounded-button/rounded-button.module';
import { SearchBarModule } from './components/search-bar/search-bar.module';
import { SearchInputModule } from './components/search-input/search-input.module';
import { ShareButtonComponent } from './components/share-button/share-button.component';
import { UserListItemModule } from './components/user-list-item/user-list-item.module';
import { WenPaletteProvider } from './directives/colorize/palette-provider';
import { FormControlledHeaderModule } from './directives/form-controlled-header/form-controlled-header.module';
import { IsCurrentUserModule } from './directives/is-current-user/is-current-user.module';
import { SearchDirectivesModule } from './directives/search-config/search-directives.module';
import { SubscribedToModule } from './directives/subscibed-to/subscribed-to.module';
import { UserNavigationModule } from './directives/user-navigation/user-navigation.module';
import { VirtualKeyboardModule } from './directives/virtual-keyboard/virtual-keyboard.module';
import { FeaturesModule } from './feature-specific/features.module';
import { FormStoreModule } from './form-store/form-store.module';
import { IosUtilsModule } from './ios-util/ios-util.module';
import { WenPipesModule } from './pipes/wen-pipes.module';
import { PortalUiKitModule } from './portal-ui-kit.module';
import { FileEventHandlerService } from './services/file-event-handler.service';
import { ForbiddenForAnonymousUserDialogService } from './services/forbidden-for-anonymous-user-dialog.service';
import { MessageFilterService } from './services/message-filter.service';
import { MissingPermissionDialogService } from './services/missing-permission-dialog.service';
import { WeLocalImageUrlWithSizeTransformator } from './util/welocal-url-with-size-transformator';
import { NotificationService } from './services/notification-service';

const PUBLIC_MODULES = [
  WenComponentsModule,
  CommonModule,
  PortalUiKitModule,
  MatButtonModule,
  PortalModule,
  MessageBoxModule,
  EmbedsModule,
  EmbeddedPageViewerModule,
  SearchBarModule,
  SearchInputModule,
  ListItemModule,
  DetailHeaderModule,
  SubscribedToModule,
  WenTestIdModule,
  IosUtilsModule,
  RoundedButtonModule,
  FocusFirstInputModule,
  FeaturesModule,
  AdaptiveBackgroundModule,
  LazyLoadModule,
  ScaleToWidthModule,
  MenuEntryModule,
  FormStoreModule,
  ImageUploadModule,
  WenPipesModule,
  MessagePreviewCardModule,
  ChannelSpecificSharedModule,
  ExpandableContainerModule,
  ThumbnailImageModule,
  FilterViewModule,
  AppBannerModule,
  InteractButtonModule,
  DataProtectionDialogModule,
  CreateWelcomeModule,
  UserNavigationModule,
  CommentMessageSendModule,
  MessageSendFieldModule,
  PaginatedScrollviewModule,
  IsCurrentUserModule,
  ChatSyncModule,
  VirtualKeyboardModule,
  UserListItemModule,
  ChristmasModule,
  DateRangePickerModule,
  ExtendedTextEditorModule,
  FormControlledHeaderModule,
  ActiveItemImageViewModule,
  LegacyQuoteModule,
  ScrollToButtonComponent,
  SearchDirectivesModule,
  RefreshBannerModule,
  PasswordVisibilityToggleModule,
];

@NgModule({
  imports: [
    TranslateModule.forChild(),
    HammerModule,
    MatDialogModule,
    ...PUBLIC_MODULES,
    WenDatepickerModule.forRoot({
      locale: DateUtil.getLocale()
    }),
  ],
  declarations: [
    NotificationCountComponent,
    EmptyListComponent,
    EmptySearchResultComponent,
    AppLogoComponent,
    ShareButtonComponent,
    FileDropOverlayComponent,
    MuteUnmuteChatButtonComponent,
    ForwardButtonComponent
  ],
  exports: [
    TranslateModule,
    NotificationCountComponent,
    EmptyListComponent,
    EmptySearchResultComponent,
    AppLogoComponent,
    ShareButtonComponent,
    ForwardButtonComponent,
    FileDropOverlayComponent,
    MuteUnmuteChatButtonComponent,
    ...PUBLIC_MODULES,
  ],
  providers: [
    WenMobileRouteDataResolver,
    WenDesktopRouteDataResolver,
    MessageFilterService,
    ForbiddenForAnonymousUserDialogService,
    MissingPermissionDialogService,
    MediaValidator,
    FileEventHandlerService,
    ChatMuteService,
    PopupEventHandler,
    NotificationService,
    {
      provide: PaletteProvider,
      useClass: WenPaletteProvider
    },
    {
      provide: ImageUrlTransformator,
      useClass: WeLocalImageUrlWithSizeTransformator
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class SharedModule { }

