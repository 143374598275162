<h1 *isDesktopView class="wen-explore-header-label">{{ 'CHANNEL_DISCOVER' | translate }}</h1>

<div class="quick-filters">
  <wen-rounded-button transparent border (click)="navigateToChannelDiscovery('clearFilter')" section-content>{{ 'CHANNEL_SHOW_ALL_BUTTON' | translate }}</wen-rounded-button>
</div>

<cdk-virtual-scroll-viewport class="wen-section-wrapper" itemSize="375" scrollStateHandler>
  @for (section of sections$ | async; track section) {
    <wen-channel-section [section]="section" (showAll)="navigateToChannelDiscovery($event)"></wen-channel-section>
  }
</cdk-virtual-scroll-viewport>
