import { Component, Input } from '@angular/core';

@Component({
  selector: 'wen-loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss'],
})
export class LoadingSkeletonComponent {

  @Input() isLoaded = false;

}
