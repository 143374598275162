import { NgModule } from '@angular/core';
import { DistributionChatAttachmentLoader } from './chat-distribution/distribution-chat-attachment-loader';
import { DistributionChatMessageProcessor } from './chat-distribution/distribution-chat-message-processor';
import { DistributionDataImporter } from './chat-distribution/distribution-data-importer';
import { SelectionDataImporter } from './selection-data-importer';
import { SD_MODULE_LOADER, SDModuleLoader } from './smartdeisgn-web-api';
import { SmartDesignApi } from './smartdesing-api';

@NgModule({
  providers: [
    {
      provide: SD_MODULE_LOADER,
      useValue: SDModuleLoader
    },
    SmartDesignApi,
    SelectionDataImporter,
    DistributionDataImporter,
    DistributionChatMessageProcessor,
    DistributionChatAttachmentLoader,
  ]
})
export class SmartDesignApiModule { }
