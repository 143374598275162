import { Pipe, PipeTransform } from '@angular/core';
import { GeoAutocompleteFeature, GeoFeatureEntityType } from '@portal/wen-backend-api';
import { LocationFormat, LocationFormatResult, LocationFormatter } from './location-formatter.service';

@Pipe({
  name: 'locationFormatter',
})
export class LocationFormatterPipe implements PipeTransform {

  constructor(
    private readonly locationFormatter: LocationFormatter
  ) { }

  transform(location: GeoAutocompleteFeature, format: LocationFormat = 'singleline'): LocationFormatResult {
    return this.locationFormatter.format(location, format);
  }

}

@Pipe({
  name: 'locationIconClassifier',
})
export class LocationIconClassifierPipe implements PipeTransform {

  transform(location: GeoAutocompleteFeature) {
    const type = location?.properties?.geoEntityType;
    switch (type) {
      case GeoFeatureEntityType.AIRPORT:
        return 'plane';
      case GeoFeatureEntityType.TRAIN_STATION:
        return 'train';
      case GeoFeatureEntityType.ATTRACTION:
        return 'camera';
      case GeoFeatureEntityType.USER_HOME:
        return 'home';
      default:
        return 'location';
    }
  }

}
