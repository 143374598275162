<div class="wen-pending-contact-list-container">
  <div class="wen-pending-contact-list-wrapper">
    <wen-list-mass-data-viewer [datasource]="listDatasource">
      <ng-container wen-list-mass-data-empty>
        <h3 class="wen-pending-contact-list-group-label">
          {{ "PENDING_CONTACTS_LIST_EMPTY_HEADER" | translate }}
        </h3>
        <wen-empty-list
        src="/assets/wen-widget/image/empty-placeholders/pic-empty-list-wen-sitting-robot.svg"></wen-empty-list>
      </ng-container>
      <ng-template #itemTemplate wen-list-mass-data-item let-listItemData let-index="index">
        @if ((listService.separatorPositions$ | async)?.incomingIndex === index) {
          <h3
            class="wen-pending-contact-list-group-label">
            {{ "PENDING_CONTACTS_LIST_HEADER" | translate }}
          </h3>
        }
        @if ((listService.separatorPositions$ | async)?.sentIndex === index) {
          <h3
            class="wen-pending-contact-list-group-label">
            {{ "PENDING_SENT_CONTACTS_LIST_HEADER" | translate }}
          </h3>
        }
        <wen-user-list-item [user]="listItemData | toUserListItem" [userNavigable]="listItemData.user.id">
          @if (listItemData.requestStatus === 'REQUESTER') {
            <div class="wen-contact-user-list-item-actions-after" wen-user-after
              >
              <wen-icon class="wen-icon-huge" [wenVariant]="'secondaryInlined'" iconName="checkmark"
              (click)="handleAcceptIncomingAfterClick($event, listItemData)"></wen-icon>
              <wen-icon class="wen-icon-huge" [wenVariant]="'secondaryInlined'" iconName="close"
              (click)="handleDenyIncomingAfterClick($event, listItemData)"></wen-icon>
            </div>
          } @else {
            <div class="wen-contact-user-list-item-actions-after" wen-list-item-after>
              <wen-contact-response-action [iconOnly]="true"
              [profileData]="convertToResponseActionData(listItemData)"></wen-contact-response-action>
            </div>
          }
        </wen-user-list-item>
      </ng-template>
    </wen-list-mass-data-viewer>
  </div>
</div>