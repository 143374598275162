import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LocationPreviewModule, MediaPreviewModule, ContentBoundaryModule } from '@portal/wen-components';
import { WenPipesModule } from '../../pipes/wen-pipes.module';
import { LinkEmbedPreviewModule } from '../link-embed-preview/link-embed-preview.module';
import { ExtendedTextEditorComponent } from './extended-text-editor.component';

@NgModule({
  imports: [
    CommonModule,
    TextFieldModule,
    MediaPreviewModule,
    LinkEmbedPreviewModule,
    LocationPreviewModule,
    FormsModule,
    WenPipesModule,
    ContentBoundaryModule
  ],
  declarations: [
    ExtendedTextEditorComponent,
  ],
  exports: [
    ExtendedTextEditorComponent
  ]
})
export class ExtendedTextEditorModule { }
