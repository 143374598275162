import { Injectable } from '@angular/core';
import { WenRouteId } from '../../../../frame/routing/types';
import { DataObjectType } from '../../../store/common/data-objects';
import { FilterEntityType } from '../../../store/filter/models/filter';
import { HeaderCommandType } from '../../../store/header/models/HeaderCommand';
import { HeaderData, SearchFilterId } from '../../../store/header/models/HeaderData';
import { createBackNavigationCommand, createCancelCommand, createCloseDialogCommand, createCloseProfileNavigationCommand, createCrmPauseMassChatSendingCommand, createCrmProfileReadOnlyViewCommand, createFinishWizardCommand, createForwardInWizardCommand, createInviteToNetworkCommand, createSaveCommand, createUserProfileNavigationCommand } from '../../../store/header/models/base-commands';
import { FeatureEnablementService } from '../../configuration/feature-enablement';
import { AppNavigator } from '../app-navigator';
import { WenNavigationHelper } from '../types';
import { WenDialogRouteData, WenResolvedRouteData } from './route-data-types';

@Injectable()
export class WenDesktopRouteDataResolver {

  constructor(
    private featureEnablement: FeatureEnablementService,
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
  ) { }

  getDesktopRouteData(primaryRouteId: WenRouteId, sidebarRouteId: WenRouteId): WenResolvedRouteData {
    switch (primaryRouteId) {
      case WenRouteId.LOGIN:
      case WenRouteId.OAUTH_CALLBACK:
        return {
          headerData: null,
          hideNavigationBar: true
        };
      case WenRouteId.ERROR_PAGE:
        return {
          headerData: {
            showLogo: true,
          },
          hideNavigationBar: false
        };
      case WenRouteId.READER_VIEW:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: false
        };
      case WenRouteId.REGISTRATION_START:
      case WenRouteId.REGISTRATION_PROFILE:
      case WenRouteId.REGISTRATION_CODE_VERIFICATION:
        return {
          headerData: null,
          hideNavigationBar: true
        };
      case WenRouteId.TEXT_VIEW:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: false
        };
      case WenRouteId.WALLET_CREDENTIAL_CREATE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createCancelCommand()
          },
          hideNavigationBar: false
        };
      case WenRouteId.WALLET_INITIATE_ISSUANCE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: false
        };
      default:
        break;
    }
    if (!sidebarRouteId) {
      return null;
    }
    switch (sidebarRouteId) {
      case WenRouteId.DASHBOARD:
      case WenRouteId.USERPROFILE_DASHBOARD:
      case WenRouteId.ERROR_PAGE:
      case WenRouteId.HOME:
      case WenRouteId.CHANNEL_VIEW:
      case WenRouteId.CHANNEL_FEATURED_VIEW:
      case WenRouteId.APP_DETAIL:
      case WenRouteId.APP_VIEW:
      case WenRouteId.APP_ADD_WELCOME:
      case WenRouteId.CHAT_VIEW:
      case WenRouteId.EMBEDDED_PAGE:
      case WenRouteId.EVENT_DETAIL:
        const fallbackHeaderData: HeaderData = {
          showLogo: true,
          leftCommand: createUserProfileNavigationCommand()
        };
        return { headerData: fallbackHeaderData, hideNavigationBar: false };
      case WenRouteId.APP_LIST:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'APPS_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.APP_LISTS
              }
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.APP_DISCOVERY:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'APP_DISCOVERY_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.APP_LISTS
              }
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.CHANNEL_LIST:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createUserProfileNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CHANNELS_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.CHANNEL_LISTS
              }
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.APP_EXPLORE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createUserProfileNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'APP_DISCOVERY_SEARCH_PLACEHOLDER',
              disabled: true,
              navigateToFn: () => this.appNavigator.navigateToRoute(WenRouteId.APP_DISCOVERY)
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.CHANNEL_DISCOVERY:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CHANNEL_DISCOVERY_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.CHANNEL_LISTS
              }
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.CHANNEL_EXPLORE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CHANNEL_DISCOVERY_SEARCH_PLACEHOLDER',
              disabled: true,
              navigateToFn: () => this.navigationHelper.navigateToChannelDiscover()
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.EVENT_LIST:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'EVENT_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.EVENT_LISTS
              }
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.EVENT_EXPLORE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createUserProfileNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'EVENT_DISCOVERY_SEARCH_PLACEHOLDER',
              disabled: true,
              navigateToFn: () => this.appNavigator.navigateToRoute(WenRouteId.EVENT_DISCOVERY)
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.EVENT_DISCOVERY:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'EVENT_DISCOVERY_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.EVENT_LISTS
              }
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.USERPROFILE_VIEW:
      case WenRouteId.USERPROFILE_MANIFEST:
      case WenRouteId.USERPROFILE_LICENSES:
      case WenRouteId.USERPROFILE_COMING_SOON:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createCloseProfileNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_HELP:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_HOTLINE:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHAT_CREATION:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createUserProfileNavigationCommand()
          },
          hideNavigationBar: false
        };
      case WenRouteId.USER_SETTINGS:
        return {
          headerData: {
            title: 'USER_PROFILE_ACCOUNT_SETTINGS',
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHAT_LIST:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createUserProfileNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CHAT_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.CHAT_LISTS
              }
            }
          },
          hideNavigationBar: false
        };
      default:
        console.warn(`Not supported route ${sidebarRouteId}`);
        return;
    }
  }

  getDesktopDialogHeaderData(dialogRouteId: WenRouteId): WenDialogRouteData {
    switch (dialogRouteId) {
      case WenRouteId.CHANNEL_DETAIL:
        return {
          headerData: {
            leftCommand: createBackNavigationCommand(),
          }
        };
      case WenRouteId.CHANNEL_SETTING:
        return {
          headerData: {
            title: 'CHANNEL_DETAIL_SETTING_TITLE',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL_SETTING),
          }
        };
      case WenRouteId.CHANNEL_POST_EDITOR:
        return {
          headerData: {
            title: 'CHANNEL_POST_EDITOR_FORM_TITLE',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL_POST),
          }
        };
      case WenRouteId.CHANNEL_POST_LOCATION_SELECTOR:
        return {
          headerData: {
            title: 'CHANNEL_POST_LOCATION_SELECTOR_FORM_TITLE',
            leftCommand: createCancelCommand(),
          }
        };
      case WenRouteId.CHAT_GROUP_DETAIL:
        return {
          headerData: {
            leftCommand: createBackNavigationCommand(),
          }
        };
      case WenRouteId.CHAT_CREATION:
        return {
          headerData: {
            title: 'CHAT_CREATION_TITLE',
            leftCommand: createBackNavigationCommand()
          },
        };
      case WenRouteId.APP_DETAIL:
        return {
          headerData: {
            leftCommand: createCloseDialogCommand(),
          }
        };
      case WenRouteId.CHANNEL_ADD_WELCOME:
        return {
          headerData: {
            contextTitle: 'CHANNELS_HEADER_TITLE',
            title: 'CREATE_CHANNEL_WELCOME',
            leftCommand: createCancelCommand()
          }
        };
      case WenRouteId.CHANNEL_INFO_EDIT:
        return {
          headerData: {
            title: 'CHANNEL_EDIT_INFO_FORM_TITLE'
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL)
          }
        };
      case WenRouteId.CHANNEL_VISIBILITY_SETTINGS:
        return {
          headerData: {
            title: 'CHANNEL_VISIBILITY_SETTINGS_FORM_TITLE',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL)
          }
        };
      case WenRouteId.CHANNEL_RESTRICTION_SETTINGS:
        return {
          headerData: {
            title: 'CHANNEL_RESTRICTION_SETTINGS_FORM_TITLE',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL)
          }
        };
      case WenRouteId.CHANNEL_INTERACTION_SETTINGS:
        return {
          headerData: {
            title: 'CHANNEL_INTERACTION_SETTINGS_FORM_TITLE',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL)
          }
        };
      case WenRouteId.OTHER_USERPROFILE_READ:
        return {
          headerData: {
            leftCommand: createBackNavigationCommand(),
            contextTitle: 'USER_PROFILE_OTHER_PROFILE'
          }
        };
      case WenRouteId.USERPROFILE_READ:
        return {
          headerData: {
            title: 'USER_PROFILE_MY_PROFILE',
            leftCommand: createBackNavigationCommand(),
          }
        };
      case WenRouteId.USERPROFILE_EDIT:
        return {
          headerData: {
            title: 'PROFILE_EDIT_TITLE',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.USER)
          }
        };
      case WenRouteId.USERPROFILE_LANGUAGE:
        return {
          headerData: {
            title: 'USER_PROFILE_LANGUAGE',
            contextTitle: 'USER_PROFILE_ACCOUNT_SETTINGS',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.LANGUAGE)
          }
        };
      case WenRouteId.USERPROFILE_EMAIL_SETTINGS:
        return {
          headerData: {
            title: 'USER_PROFILE_EMAIL_SETTINGS_HEADER_TITLE',
            leftCommand: createCancelCommand(),
          }
        };
      case WenRouteId.USERPROFILE_CHANGE_EMAIL:
        return {
          headerData: {
            title: 'USER_PROFILE_EMAIL_CHANGE_FORM_TITLE',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.USER)
          }
        };
      case WenRouteId.USERPROFILE_CHANGE_PASSWORD:
        return {
          headerData: {
            title: 'USER_PROFILE_PASSWORD_EDIT_FORM_HEADER_LABEL',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE)
          }
        };
      case WenRouteId.USERPROFILE_CHANGE_PASSWORD_VERIFY_CODE:
        return {
          headerData: {
            title: 'USER_PROFILE_PASSWORD_EDIT_FORM_HEADER_LABEL',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.USER_PASSWORD)
          }
        };
      case WenRouteId.USERPROFILE_SETTINGS:
        return {
          headerData: {
            title: 'USER_PROFILE_SETTINGS',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.USER)
          }
        };
      case WenRouteId.USERPROFILE_CREDENTIALS_MENU:
        return {
          headerData: {
            title: 'USER_PROFILE_CREDENTIALS_MENU',
            leftCommand: createBackNavigationCommand(),
          }
        };
      case WenRouteId.USERPROFILE_CREDENTIAL_DETAILS:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
          }
        };
      case WenRouteId.USERPROFILE_PRIVACY_SETTINGS:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_PRIVACY_SETTINGS',
            contextTitle: 'USER_PROFILE_ACCOUNT_SETTINGS',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.PRIVACY_SETTINGS)
          }
        };
      case WenRouteId.USERPROFILE_PERSONAL_ACCOUNT_SETTINGS:
        return {
          headerData: {
            title: 'USER_PROFILE_PERSONAL_ACCOUNT_SETTINGS',
            leftCommand: createBackNavigationCommand()
          }
        };
      case WenRouteId.USERPROFILE_LOCATION_SELECTOR:
        return {
          headerData: {
            title: 'USER_PROFILE_SELECT_LOCATION',
            leftCommand: createCancelCommand()
          }
        };
      case WenRouteId.CONTACTS_LIST:
        return {
          headerData: {
            showLogo: false,
            title: 'CONTACTS_LIST_HEADER',
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CONTACTS_LIST_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.CONTACT_SEARCH,
                filterEntityType: FilterEntityType.CONTACTS_USER_LIST_FILTER
              }
            }
          },
        };
      case WenRouteId.PENDING_CONTACTS_LIST:
        return {
          headerData: {
            showLogo: false,
            title: 'PENDING_CONTACTS_LIST_HEADER',
            leftCommand: createBackNavigationCommand(),
          },
        };
      case WenRouteId.INVITE_TO_NETWORK:
        return {
          headerData: {
            title: 'USER_PROFILE_INVITE_HEADER_TITLE',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createInviteToNetworkCommand()
          }
        };
      case WenRouteId.INVITE_TO_CHANNEL_SELECTOR:
        return {
          headerData: {
            title: 'INVITE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'INVITE_CHANNEL_WIZARD_STEP_1_SUBTITLE'
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          }
        };
      case WenRouteId.INVITE_TO_CHANNEL:
        return {
          headerData: {
            title: 'INVITE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'INVITE_CHANNEL_WIZARD_STEP_2_SUBTITLE'
          },
          footerData: {
            leftCommand: createBackNavigationCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.INVITE_TO_CHANNEL),
          }
        };
      case WenRouteId.CRM_ACTION_SELECTOR:
        return {
          headerData: {
            title: 'CRM_ACTION_SELECTOR_HEADER_TITLE',
            rightCommand: createCrmProfileReadOnlyViewCommand()
          }
        };
      case WenRouteId.CRM_PROFILE_READ_ONLY_VIEW:
        return {
          headerData: {
            leftCommand: createBackNavigationCommand()
          },
        };
      case WenRouteId.CRM_CHAT_PREVIEW:
        return {
          headerData: {
            showLogo: true
          },
        };
      case WenRouteId.CRM_DISTRIBUTE_CHAT:
        return {
          headerData: {
            showLogo: true,
            title: 'CRM_DISTRIBUTE_CHAT_HEADER_TITLE',
            leftCommand: this.featureEnablement.isEnableCRMDistributionCancelling() ?
              createCrmPauseMassChatSendingCommand() : null
          }
        };
      case WenRouteId.APP_ADD_WELCOME:
        return {
          headerData: {
            title: 'CREATE_APP_WIZARD_FORM_TITLE',
            contextTitle: 'APPS_HEADER_TITLE',
            leftCommand: createCancelCommand(),
          }
        };
      case WenRouteId.ADD_CHANNEL_INFO:
        return {
          headerData: {
            contextTitle: 'CHANNELS_HEADER_TITLE',
            title: 'CREATE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'CREATE_CHANNEL_WIZARD_STEP_1_SUBTITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          }
        };
      case WenRouteId.ADD_CHANNEL_VISIBILITY:
        return {
          headerData: {
            contextTitle: 'CHANNELS_HEADER_TITLE',
            title: 'CREATE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'CREATE_CHANNEL_WIZARD_STEP_2_SUBTITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          }
        };
      case WenRouteId.ADD_CHANNEL_RESTRICTIONS:
        return {
          headerData: {
            contextTitle: 'CHANNELS_HEADER_TITLE',
            title: 'CREATE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'CREATE_CHANNEL_WIZARD_STEP_3_SUBTITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          }
        };
      case WenRouteId.ADD_CHANNEL_INTERACTIONS:
        return {
          headerData: {
            contextTitle: 'CHANNELS_HEADER_TITLE',
            title: 'CREATE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'CREATE_CHANNEL_WIZARD_STEP_4_SUBTITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.CHANNEL),
          }
        };
      case WenRouteId.CHANNEL_PENDING_JOIN_REQUESTS:
        return {
          headerData: {
            title: 'CHANNEL_PENDING_JOIN_REQUESTS_FORM_TITLE',
            leftCommand: createBackNavigationCommand(),
          }
        };
      case WenRouteId.APP_INFO_EDIT:
        return {
          headerData: {
            title: 'APP_EDIT_INFO_FORM_TITLE'
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.APP)
          }
        };
      case WenRouteId.ADD_APP_INFO:
        return {
          headerData: {
            title: 'CREATE_APP_WIZARD_FORM_TITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.APP),
          }
        };
      case WenRouteId.CHANNEL_ADMIN_LIST:
        return {
          headerData: {
            title: 'CHANNEL_ADMIN_LIST_FORM_TITLE',
            leftCommand: createBackNavigationCommand(),
          }
        };
      case WenRouteId.CHANNEL_ADD_ADMIN:
        return {
          headerData: {
            title: 'CHANNEL_ADD_ADMIN_TITLE',
            searchConfig: {
              searchBarPlaceholder: 'CHANNEL_ADD_ADMIN_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.ADMIN_SEARCH,
                filterEntityType: FilterEntityType.CHANNEL_ADD_ADMIN_LISTS
              },
            }
          },
          footerData: {
            leftCommand: createCancelCommand()
          }
        };
      case WenRouteId.CHANNEL_CONTENT_PROVIDERS:
        return {
          headerData: {
            title: 'CHANNEL_CONTENT_PROVIDER_TITLE',
            leftCommand: createBackNavigationCommand()
          }
        };
      case WenRouteId.CHANNEL_ADD_CONTENT_PROVIDER:
        return {
          headerData: {
            title: 'CHANNEL_ADD_CONTENT_PROVIDER_TITLE',
            searchConfig: {
              searchBarPlaceholder: 'CHANNEL_ADD_CONTENT_PROVIDER_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.USER_SEARCH,
                filterEntityType: FilterEntityType.CHANNEL_ADD_CONTENT_PROVIDER_LISTS
              },
            }
          },
          footerData: {
            leftCommand: createCancelCommand()
          }
        };
      case WenRouteId.CHANNEL_SUBSCRIBERS:
        return {
          headerData: {
            title: 'CHANNEL_SUBSCRIBERS_TITLE',
            leftCommand: createBackNavigationCommand()
          }
        };
      case WenRouteId.ADD_CHAT_USERS:
        return {
          headerData: {
            title: 'CREATE_GROUP_CHAT_WIZARD_TITLE',
            subTitle: 'CREATE_GROUP_CHAT_WIZARD_STEP_1_SUBTITLE',
            contextTitle: 'CHAT_CONTEXT_TITLE',
            leftCommand: createCancelCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          }
        };
      case WenRouteId.ADD_CHAT_INFO:
        return {
          headerData: {
            title: 'CREATE_GROUP_CHAT_WIZARD_TITLE',
            subTitle: 'CREATE_GROUP_CHAT_WIZARD_STEP_2_SUBTITLE',
            contextTitle: 'CHAT_CONTEXT_TITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.CHAT),
          }
        };
      case WenRouteId.AUTO_REPLY:
        return {
          headerData: {
            title: 'USER_PROFILE_AUTO_REPLY',
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.AUTO_REPLY)
          }
        };
      case WenRouteId.CHAT_INFO_EDIT:
        return {
          headerData: {
            title: 'EDIT_GROUP_CHAT_WIZARD_TITLE'
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHAT),
          }
        };
      case WenRouteId.ADD_CHAT_GROUP_MEMBERS:
        return {
          headerData: {
            title: 'GROUP_CHAT_ADD_MEMBERS'
          },
          footerData: {
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHAT_MEMBERS)
          }
        };
      case WenRouteId.EVENT_DETAIL:
        return {
          headerData: {
            contextTitle: 'EVENT_CONTEXT_TITLE'
          }
        };
      default:
        console.warn(`Not supported route ${dialogRouteId}`);
        return {};
    }
  }
}
