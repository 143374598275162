import { NgModule } from '@angular/core';
import { LoadingSkeletonComponent } from './loading-skeleton.component';
import { SkeletonContentDirective, LoadedContentDirective } from './loading-skeleton.directives';

@NgModule({
  declarations: [
    LoadingSkeletonComponent,
    SkeletonContentDirective,
    LoadedContentDirective
  ],
  exports: [
    LoadingSkeletonComponent,
    SkeletonContentDirective,
    LoadedContentDirective
  ],
})
export class LoadingSkeletonModule { }
