import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, shareReplay, switchMap } from 'rxjs';
import { selectCurrentChannel } from '../../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';
import { ChannelViewPreloaderGuard } from '../../../../common/providers/channel-view-preloader.guard';

@Injectable()
export class ChannelSelector {
  private channelViewPreloaderGuard = inject(ChannelViewPreloaderGuard);
  private store = inject(Store<RootState>);

  currentChannel$ =
    this.channelViewPreloaderGuard.ensureCurrentChannelDetailsLoaded().pipe(
      switchMap(() => this.store.pipe(
        select(selectCurrentChannel),
        distinctUntilChanged((a, b) => a?.id === b?.id),
        shareReplay(1)
      ))
    );

}
