<wen-form-progress [hasProgress]="hasProgress$ | async"></wen-form-progress>
<div class="sign-in-login-content">
  <div class="sign-in-login-logo-wrapper">
    <h1 class="screenreader-only">{{'HOME_PAGE_WELCOME_TITLE' | translate }} {{'HOME_PAGE_WELCOME_TITLE_APP' | translate }}</h1>
    <img class="sign-in-login-logo" [alt]="'APP_LOGO_DESCRIPTION' | translate" />
  </div>
  <div class="sign-in-login-controls-wrapper">
    @if (config.showLoginPrompt) {
      <div portal-title class="sign-in-login-prompt">{{'ONBOARDING_LOGIN_PROMPT' | translate}}</div>
    }
    <div class="sign-in-login-controls-buttons">
      @if (config.showRegisterButton) {
        <wen-rounded-button portal-title (click)="goToRegister()">{{ 'ONBOARDING_LOGIN_REGISTER' | translate }}</wen-rounded-button>
      }
      @if (config.showAnonLoginButton) {
        <wen-rounded-button transparent border (click)="goToAnonymousLogin()">{{ 'ONBOARDING_LOGIN_LOGIN_ANONYMOUS' | translate }}</wen-rounded-button>
      }
      @if (config.showLoginButton) {
        <wen-rounded-button transparent (click)="goToLogin()">
          <span [innerHtml]="'ONBOARDING_LOGIN_LOGIN' | translate"></span>
        </wen-rounded-button>
      }
    </div>
    @if (config.termsAndConditions) {
      <div class="sign-in-login-data-policy" portal-subheading-1>
        {{ 'ONBOARDING_LOGIN_DATA_POLICY_1' | translate }}
        <a portal-subheading-1 [href]="'PRIVACY_POLICY_URL' | translate" target="_blank" (click)="openPrivacyPage()">{{ 'ONBOARDING_LOGIN_DATA_POLICY_2' | translate }}</a>
        {{ 'ONBOARDING_LOGIN_DATA_POLICY_3' | translate }}
        <a portal-subheading-1 [href]="'TERMS_AND_CONDITIONS_URL' | translate" target="_blank" (click)="openTermsAndConditionsPage()">{{ 'ONBOARDING_LOGIN_DATA_POLICY_4' | translate }}.</a>
      </div>
    }
  </div>
</div>
<wen-silent-logout></wen-silent-logout>
