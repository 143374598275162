import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogComponent, ConfirmationDialogData, SelectionOption, OverlayManager, WenSnackbarOpener } from '@portal/wen-components';
import { Observable, Subject, of, withLatestFrom, merge } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { isNullOrUndefined } from '../../../../../core/common/operators/null-check-util';
import { TrimFieldValueAdapter } from '../../../../../core/common/util/field-adapters/trim-field-value-adapter';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { toSelectionOptions } from '../../../../../core/common/util/to-selection-option';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { selectChannelPermission } from '../../../../../core/store/channel/channel.selectors';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { RootState } from '../../../../../core/store/root/public-api';
import { ColorPalette, GreyPalette } from '../../../../../shared/directives/colorize/palette-provider';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { CHANNEL_EDIT_FORM_DATA_PROVIDER, ChannelFormDataProvider } from '../../../common/providers/channel-form-data-provider';
import { HeaderDataHelper } from '../../../../../core/services/util/header-data-helper';

@Component({
  selector: 'wen-channel-info-edit',
  templateUrl: './channel-info-edit.component.html',
  styleUrls: ['./channel-info-edit.component.scss'],
  providers: [
    CHANNEL_EDIT_FORM_DATA_PROVIDER
  ]
})
export class ChannelInfoEditComponent implements OnInit, AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  readonly CHARACTER_LIMIT = 400;
  private readonly SHOW_CHARACTER_COUNT = Math.ceil(this.CHARACTER_LIMIT * 0.1);
  fieldValueAdapter = new TrimFieldValueAdapter();
  characterCount$: Observable<number | boolean>;

  channelEditFormGroup = new FormGroup({
    icon: new FormControl(''),
    title: new FormControl('', {
      validators: [
        Validators.required
      ],
      updateOn: 'blur'
    }),
    description: new FormControl(''),
    categories: new FormControl([]),
    geo: new FormControl(''),
    collaborative: new FormControl(false),
    textToSpeech: new FormControl(''),
    imprint: new FormControl(''),
    dataProtectionUrl: new FormControl('')
  });

  allCategories$: Observable<SelectionOption<string>[]>;
  channel$: Observable<ChannelEntity>;
  canDelete$: Observable<boolean>;
  canOpenCategorySelector$: Observable<boolean>;
  canUpdateRestrictions$: Observable<boolean>;

  get visibilitySettingsMenuEntryFillColor(): ColorPalette {
    return ColorPalette.LIGHT_BLUE;
  }
  get restrictionSettingsMenuEntryFillColor(): ColorPalette {
    return ColorPalette.ORANGE;
  }
  get interactionSettingsMenuEntryFillColor(): ColorPalette {
    return ColorPalette.GREEN;
  }
  get inactiveMenuEntryFillColor(): GreyPalette {
    return GreyPalette.GREY5;
  }

  get imageFallbackText() {
    return this.channelEditFormGroup.controls.title.value;
  }

  get showGeoToggle() {
    return typeof this.channelEditFormGroup.controls.geo.value === 'boolean';
  }

  get showCollaborativeToggle() {
    return typeof this.channelEditFormGroup.controls.collaborative.value === 'boolean';
  }

  get showTextToSpeechToggle() {
    return this.featureEnablementService.featureFlagMethods.isEnableTextToSpeech();
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly socketIoService: SocketIoService,
    private readonly overlayManager: OverlayManager,
    private readonly channelFormDataProvider: ChannelFormDataProvider,
    private readonly translateService: TranslateService,
    private readonly formStoreService: FormStoreService,
    private readonly snackbar: WenSnackbarOpener,
    public readonly featureEnablementService: FeatureEnablementService,
    private headerDataHelper: HeaderDataHelper
  ) { }

  ngOnInit(): void {
    this.headerDataHelper.initializeContextTitleWithChannelTitle(true);

    this.channel$ = this.channelFormDataProvider.channel$;
    this.channel$.pipe(
      firstExisty(),
      map(({ id, title, description, icon, categories, geo, collaborative, textToSpeech, imprint, dataProtectionUrl }) => {
        return {
          id,
          title,
          description,
          icon,
          geo: geo || false,
          collaborative: collaborative || false,
          textToSpeech: textToSpeech || false,
          categories: categories?.length ? toSelectionOptions(categories) : [],
          imprint,
          dataProtectionUrl
        };
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(data => {
      this.formStoreService.initializeForm(data);
    });

    const channelPermission$ = this.channel$.pipe(
      switchMap((channel) => {
        if (isNullOrUndefined(channel)) {
          return of(null);
        }
        return this.store.pipe(
          selectorWithParam(selectChannelPermission, channel.id),
          takeUntil(this.onDestroy$)
        );
      }),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );

    this.canDelete$ = channelPermission$.pipe(
      map(permission => permission?.canDelete),
      takeUntil(this.onDestroy$)
    );

    this.canUpdateRestrictions$ = channelPermission$.pipe(
      map(permission => permission?.canUpdateRestrictions),
      takeUntil(this.onDestroy$)
    );

    this.allCategories$ = this.socketIoService.channel.getCategories.listen.pipe(
      first(),
      map((categories) => categories.filter(category => !Boolean(category.system))),
      map((categories) => toSelectionOptions(categories)),
      shareReplay(1)
    );

    this.canOpenCategorySelector$ = this.allCategories$.pipe(
      map((selections) => selections.length > 0),
    );

    this.socketIoService.channel.getCategories.emit();

    this.characterCount$ = merge(
      this.channel$.pipe(map(result => result?.imprint)),
      this.channelEditFormGroup.controls.imprint.valueChanges
    ).pipe(
      map(value => (value && /\S/.test(value)) ? value?.length : 0),
      map(value => value >= this.SHOW_CHARACTER_COUNT ? value : false)
    );

  }

  ngAfterViewInit(): void {
    this.setupCollaborativeValueChange();
  }

  editChannelVisibilitySettings(channelId: string) {
    this.navigationHelper.navigateToChannelVisibilitySettings(channelId);
  }

  editChannelRestrictionSettings(channelId: string) {
    this.navigationHelper.navigateToChannelRestrictionSettings(channelId);
  }

  editChannelInteractionSettings(channelId: string) {
    this.navigationHelper.navigateToChannelInteractionSettings(channelId);
  }

  deleteChannel(channelId: string) {
    if (!channelId) {
      return;
    }
    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('DELETE_CONFIRM_DIALOG_TITLE'),
      content: this.translateService.instant('CHANNEL_DELETE_CONFIRM_DIALOG_TEXT'),
      reasonFieldLabel: this.translateService.instant('CHANNEL_MESSAGE_DELETE_REASON_FIELD_LABEL'),
      okLabel: this.translateService.instant('CHANNEL_DELETE_BUTTON'),
      dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL'),
    });

    dialog.afterClosed().pipe(
      first(),
      filter(data => data?.result === 'ok'),
    ).subscribe((data) => {
      this.socketIoService.channel.delete.emit({ id: channelId, deletedReason: data.reason });
      this.formStoreService.clearFormData();
      this.navigationHelper.doUserChannelListBackNavigation();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupCollaborativeValueChange() {
    this.channelEditFormGroup.controls.collaborative.valueChanges.pipe(
      withLatestFrom(this.channel$),
      filter(([_, channel]) => !!channel.id),
      switchMap(([isCollaborationAllowed]) => {
        const prefixParam = isCollaborationAllowed ? '' : this.translateService.instant('CHANNEL_NON_COLLABORATIVE');
        const contentTranslationKey = isCollaborationAllowed
          ? 'CHANNEL_COLLABORATIVE_CONTENT_ALLOW' : 'CHANNEL_COLLABORATIVE_CONTENT_DISALLOW';
        const confirmationDialogData: ConfirmationDialogData = {
          header: this.translateService.instant('CHANNEL_COLLABORATIVE_HEADER', { prefix: prefixParam }),
          content: this.translateService.instant(contentTranslationKey),
          okLabel: this.translateService.instant('CHANNEL_COLLABORATIVE_OK_BUTTON', { prefix: prefixParam }),
          dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL'),
        };
        const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, confirmationDialogData);
        return dialog.afterClosed().pipe(
          first(),
          map((dialogResult) => dialogResult?.result),
          filter((result) => result === 'dismiss' || isNullOrUndefined(result))
        );
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.channelEditFormGroup.controls.collaborative.setValue(
        !this.channelEditFormGroup.controls.collaborative.value,
        { emitEvent: false }
      );
    });
  }

  clearInput() {
    this.channelEditFormGroup.controls.imprint.markAsDirty();
    this.channelEditFormGroup.controls.imprint.setValue('');
  }

  showReadingChannelToastMessage() {
    this.snackbar.openNotificationSnackbar({
      notificationText: this.translateService.instant('CHANNEL_RESTRICTION_SETTINGS_READING_CHANNEL_SNACK_BAR'),
      notificationIcon: 'error',
    });
  }
}
