import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, ViewEncapsulation } from '@angular/core';
import { ColorizeModule } from '../../directives/colorize/colorize.module';
import { PaletteProvider } from '../../directives/colorize/palette-provider';
import { RemoveMarkdownModule } from '../../pipes/remove-markdown/remove-markdown.module';
import { QuoteData } from './models/models';
import { EmbedDescriptionComponent } from '../embed-description/embed-description.component';
import { ThumbnailImageModule } from '../thumbnail-image/thumbnail-image.module';
import { Thumbnail } from '../thumbnail-image/thumbnail-image-model';
import { EmbedThumbnailManager } from '../../util/embed-thumbnail-manager';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { DeletedIndicatorComponent } from '../deleted-indicator/deleted-indicator.component';
import { EmbedDTOTypes, isLinkEmbed, isMediaEmbed, isPollEmbed } from '@portal/wen-backend-api';
import { UrlMediaEmbed } from '../media-preview/media-embed-handlers';

@Component({
  selector: 'wen-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgStyle,
    RemoveMarkdownModule,
    ColorizeModule,
    EmbedDescriptionComponent,
    ThumbnailImageModule,
    TranslateModule,
    WenIconModule,
    DeletedIndicatorComponent
  ]
})
export class QuoteComponent {

  private palette = inject(PaletteProvider);
  private embedThumbnailManager = new EmbedThumbnailManager();
  private quoteData: QuoteData;
  borderColor: string;
  embedThumbnail: Thumbnail;
  embedIcon: string;

  get quote() {
    return this.quoteData;
  }

  @Input() set quote(value: QuoteData) {
    this.quoteData = value;
    this.borderColor = this.setBorderColor(value);
    const embed = value.embed;
    this.embedIcon = null;
    this.embedThumbnail = null;
    if (isMediaEmbed(embed)) {
      const mediaHandler = new UrlMediaEmbed(embed);
      this.embedIcon = mediaHandler.getIconName();
      this.embedThumbnail = { url: mediaHandler.getThumbanilSrc(), width: embed.width, height: embed.height };
    } else if (isLinkEmbed(embed)) {
      this.embedThumbnail = this.embedThumbnailManager.getEmbedThumbnail(embed);
    } else {
      this.embedIcon = this.getPreviewIcon(embed);
    }
  }

  getPreviewIcon(embed: EmbedDTOTypes) {
    if (isPollEmbed(embed)) {
      return 'chart';
    }

    return null;
  }

  @HostBinding('class.wen-quote') className = true;

  private setBorderColor(quoteData: QuoteData) {
    return this.palette.getColorFor(quoteData.author);
  }

}
