import { Component, Inject, OnInit } from '@angular/core';
import { ChannelMessageDetailModule } from '../../channel-message-detail.module';
import { HeaderModule } from '../../../../../frame/components/header/header.module';
import { ActiveItemImageViewModule } from '../../../../../shared/components/active-item-image-view/active-item-image-view.module';
import { TranslateModule } from '@ngx-translate/core';
import { first, map, Observable } from 'rxjs';
import { FeedDatasource } from '../../../../../shared/components/feed/providers/feed-datasource';
import { ChannelMessageDetailDatasource } from '../../providers/channel-message-detail.datasource';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { getChannelIndicatorType } from '@portal/wen-components';

@Component({
  selector: 'wen-channel-message-detail-desktop',
  standalone: true,
  imports: [
    ChannelMessageDetailModule,
    HeaderModule,
    ActiveItemImageViewModule,
    TranslateModule
  ],
  providers: [
    { provide: FeedDatasource, useClass: ChannelMessageDetailDatasource }
  ],
  templateUrl: './channel-message-detail-desktop.component.html',
  styleUrl: './channel-message-detail-desktop.component.scss'
})
export class ChannelMessageDetailDesktopComponent implements OnInit {

  channelImageUrl$: Observable<string>;
  channelPlaceholder$: Observable<string>;
  channelOverlayData$: Observable<string>;

  constructor(@Inject(FeedDatasource) private datasource: ChannelMessageDetailDatasource,) {
  }

  ngOnInit() {
    const channel$ = this.datasource.channel$.pipe(
      first()
    );

    this.channelImageUrl$ = channel$.pipe(
      map((channel: ChannelEntity) => channel.icon)
    );

    this.channelPlaceholder$ = channel$.pipe(
      map((channel: ChannelEntity) => channel.title)
    );

    this.channelOverlayData$ = channel$.pipe(
      map((channel: ChannelEntity) =>
        getChannelIndicatorType(channel.geo, channel.readingContent, channel.restricted, channel.isPrivate))
    );
  }

}
